import React, { useState, useRef } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";
import {  ACCESS_TOKEN } from '../constants';



import './Login.css';
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = () => {
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  
 const [ propTermsOfService , setPropTermsOfService ] = useState(false);
 
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };


  const onPropTermsOfService  = (e) => {
	
   // const propTermsOfService = e.target.checked;
    alert("checkbox propTermsOfService value is:" + e.target.checked);
    setPropTermsOfService(e.target.checked);
  };
  
  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password).then(
        () => {
	
          const currentUser = AuthService.getCurrentUser();

      
	      localStorage.setItem(ACCESS_TOKEN,currentUser.accessToken)
          navigate("/profile");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
	
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form className="formStyle inputFONTSize" onSubmit={handleLogin} ref={form}>
          <div className="form-group ">
            <label htmlFor="username">Username</label>
            <Input
              type="text"
              className="form-control fs-2"
              name="username"
              value={username}
              onChange={onChangeUsername}
              validations={[required]}
            />
          </div>

          <div className="form-group inputFONTSize">
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              className="form-control fs-2"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required]}
            />
          </div>
          
          <br />
<ul id="menu">
     <li>
              <Link to={"/privacyPolicy"} >
                Privacy Policy
              </Link>
            </li>
      <li>
              <Link to={"/termsAndConditions"} >
                Terms And Conditions
              </Link>
            </li>
</ul> 
                               
<br />
          
							
{/*  add checkbox name field 

   <div className="checkbox-wrapper">

 */}
  
<div className="form-group inputFONTSize">
   <label>
 <input
          
            type="checkbox"
             checked={propTermsOfService}
            
              onChange={onPropTermsOfService}
             
            />
 <span>   I Agree to Terms of Service and have read Fully Privacy Policy and Terms and Conditions</span>
        
          </label>   

          </div>

                               

          <div className="form-group">
            <button className="btn btn-primary btn-block  " disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span  className="btnFontSize">Login</span>
            </button>
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  
  );
};

export default Login;
