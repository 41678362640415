import React, { Component } from 'react';
// import dynamic from 'next/dynamic';
import ReactQuill from 'react-quill';
import './MyComponent.css';
import MyEditor from "./MyEditor";
import axios from "axios";
// import myComponentClient from "./MyComponentClient";
// mport myComponentClient2 from "./MyComponentClient2";
//import Modal from '@material-ui/core/Modal';
// import Button from '@material-ui/core/Button';
import '../../../node_modules/react-quill/dist/quill.snow.css';
//ref : https://kaloraat.com/articles/react-quill-wysiwyg-rich-text-editor-image-upload-to-server

import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../../constants';


const Quill = ReactQuill.Quill
var Font = Quill.import('formats/font');

Font.whitelist = ['Arial' ,
                  'TimesNewRoman' , 'Helvetica', 'sans-serif' , 
                  "Montserrat" , "Lato" , "Rubik",
                  'Ubuntu', 'Raleway', 'Roboto'    ];

Quill.register(Font, true);


let SizeStyle = Quill.import('attributors/style/size');

SizeStyle.whitelist = ["9px","10px","11px" ,"12px","14px","16px" ,"18px","20px","22px","24px" , 

                       "26px"  , "28px",
                       "32px" , "36px" , "40px" , "44px" , "48px" , "54px" , "60px" , "66px" , "72px" , 
                        "80px" , "88px" , "96px"]

Quill.register(SizeStyle, true);




class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { editorHtml: '' };
        this.state = { editor: 'start' };
  

     //   this.handleChange = this.handleChange.bind(this);

    }


	
    render() {
	


        return (

                          
                                
            <div id="parentId" className="text-editor">
                {JSON.stringify(this.state.editorHtml)}
                <hr />
                

                
                  <MyEditor
        handleChange={(data) => { 
	   console.log("data returned is :" + data );   
     // alert()
          this.setState({ editorHtml: data }); 
          this.props.parentCallback(data); 
        }}
        data={this.state.editor}
        {...this.props}
      />
      <p> {JSON.stringify(this.state.editorHtml)}</p>
                 

         
            </div>
        );
    }
}
export default MyComponent;