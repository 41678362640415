import axios from "axios";
import authHeader from "./auth-header";
import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../constants';
         
// const API_URL = BLOG_SITE_BASE_URL + "/api/test/";
const API_URL = 'https://goproperties.in:8443' +  "/api/test/";
const getPublicContent = () => {
	
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

const UserService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
};

export default UserService;
