import { useState, useEffect } from "react";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
// import CommentClient from "./CommentClient";


import {
  getComments as getCommentsApi,
  createComment as createCommentApi,
  updateComment as updateCommentApi,
  deleteComment as deleteCommentApi,
} from "./CommentClient";

const Comments = ({ commentsUrl, currentUserId, postId }) => {
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const rootComments = backendComments.filter(
    (backendComment) => backendComment.parentId === null
  );
  const getReplies = (commentId) =>
    backendComments
      .filter((backendComment) => backendComment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
  const addComment = ( text,  parentId) => {
	
	alert("addComment : text  :" + text + "parentid:" + parentId);
	console.log("addComment : text  :" + text + "parentid:" + parentId);
    createCommentApi(1 , text,  parentId).then((comment) => {
	console.log("comment added");
	 console.log("addComment : CommentClient.createComment  JSON.stringify(comment)  is:" 
	  + JSON.stringify(comment));
	  
	  alert("comment added");
	  alert("addComment : CommentClient.createComment  JSON.stringify(comment)  is:" 
	  + JSON.stringify(comment.data));
	  
	  
	  alert("check backendComments");
	    alert("comment/reply  data after  update is before  backendComments.length:"  + backendComments.length);
	  
	  setBackendComments(backendComments => [...backendComments, comment.data]);
    //  setBackendComments([comment, ...backendComments]);
      
      alert("comment/reply  data after  update is after backendComments.length:"  + backendComments.length);
       alert("comment/reply  data after  update is :");
      for( var i=0; i < backendComments.length; i++){
            alert("element: " + i + "json.stringify:" + JSON.stringify(backendComments[i]));
            console.log("element: " + i + "json.stringify:" + JSON.stringify(backendComments[i]));
          }
      
      
      setActiveComment(null);
    });
  };

  const updateComment = (text, commentId) => {
    updateCommentApi(text).then(() => {
      const updatedBackendComments = backendComments.map((backendComment) => {
        if (backendComment.id === commentId) {
          return { ...backendComment, body: text };
        }
        return backendComment;
      });
      setBackendComments(updatedBackendComments);
      setActiveComment(null);
    });
  };
  const deleteComment = (commentId) => {
    if (window.confirm("Are you sure you want to remove comment?")) {
      deleteCommentApi().then(() => {
        const updatedBackendComments = backendComments.filter(
          (backendComment) => backendComment.id !== commentId
        );
        setBackendComments(updatedBackendComments);
      });
    }
  };
  
/*
  useEffect(() => {
    getCommentsApi().then((data) => {
      setBackendComments(data);
    });
  }, []);
*/
  useEffect(() => {
	//const postId = 1;
	alert(" useeffect CommentClient.getCommentsApi" );
	console.log(" COMMENTS : USEEFFECT :  useeffect CommentClient.getCommentsApi" );
	console.log(" COMMENTS : USEEFFECT :  POSTID is: " + JSON.stringify(postId));;
      getCommentsApi(postId).then((data) => {
	  alert("axios db comments data is useeffect 1: "+  JSON.stringify(data))
      setBackendComments(data.data);
       alert("axios db backendComments  is useeffect 2: "+  JSON.stringify(data))
        alert("axios db backendComments  is useeffect 3: "+  JSON.stringify(backendComments))
    });
  }, []);


  return (
    <div className="comments">
      <h3 className="comments-title">Comments</h3>
      <div className="comment-form-title">Write comment</div>
      <CommentForm submitLabel="Write" handleSubmit={addComment} />
      <div className="comments-container">
   
        {rootComments.map((rootComment) => (
	     
          <Comment
            key={rootComment.id}
            comment={rootComment}
            replies={getReplies(rootComment.id)}
            activeComment={activeComment}
            setActiveComment={setActiveComment}
            addComment={addComment}
            deleteComment={deleteComment}
            updateComment={updateComment}
            currentUserId={currentUserId}
          />
        ))}
      </div>
    </div>
  );
};

export default Comments;
