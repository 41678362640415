


import React, { useState } from 'react';
import { useNavigate, NavLink } from "react-router-dom";

import FetchBlog from './components/FetchBlog';


const BlogList = (props) => {
      // ***   alert("Current BLOGLIST  user logged");
       // ***  alert("Current currentUser user logged in" + props.user.username);
      // ***   alert("Strigify Current currentUser user logged in" + JSON.stringify(props.user));
		return (
			<div>
                
			     
			{/*	<p> Current AppList user logged in :${JSON.stringify(props.user)} </p>  */}

	   <p> Current User logged in :  {props.user.username} </p>
		{/*	    <col>
		   <!-- Revive Adserver iFrame Tag - Generated with Revive Adserver v5.3.1 --> 
<iframe id='a551a7e4' name='a551a7e4' 
src='http://localhost/adserver/www/delivery/afr.php?zoneid=7&amp;cb=INSERT_RANDOM_NUMBER_HERE' 
frameborder='0' scrolling='no' 
allow='autoplay'>
<a href='http://localhost/adserver/www/delivery/ck.php?n=ae0a2773&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'>
<img src='http://localhost/adserver/www/delivery/avw.php?zoneid=7&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=ae0a2773' 
border='0' alt='' />
</a>
</iframe>

		    </col>*/}

  

<div> 
		    
	{/*		<div className="d-flex justify-content-center">   */}
		
		<FetchBlog user={props.user} />  
           </div>
           </div>
         
    
    );
	}


export default BlogList;