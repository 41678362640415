import React, { Component } from 'react';
// import dynamic from 'next/dynamic';
import ReactQuill from 'react-quill';
import editBlogClient from './editBlogClient';

// to do - change for ckeditor ???????????????//
import "./MyEditComponent.css";
import "../quillImageVideoCSS/QuillImageVideoCSS.css";
import myEditComponentClient from "./MyEditComponentClient";
import myComponentClient2 from "../postComponents/QuillAddComponent/MyComponentClient2";
import showBlogClient from '../showBlogComponents/showBlogClient';

import axios from "axios";

import DOMPurify from 'dompurify';

import { Form  , Table ,  ButtonGroup,  Button } from 'react-bootstrap';

import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../constants';

import {Navigate } from 'react-router-dom';

import ReactPlayer from 'react-player';
import MyEditor from "../postComponents/QuillAddComponent/MyEditor";

//import Modal from '@material-ui/core/Modal';
// import Button from '@material-ui/core/Button';
import '../../node_modules/react-quill/dist/quill.snow.css';
//ref : https://kaloraat.com/articles/react-quill-wysiwyg-rich-text-editor-image-upload-to-server




const Quill = ReactQuill.Quill
var Font = Quill.import('formats/font');

Font.whitelist = ['Arial' ,
                  'TimesNewRoman' , 'Helvetica', 'sans-serif' , 
                  "Montserrat" , "Lato" , "Rubik",
                  'Ubuntu', 'Raleway', 'Roboto'    ];

Quill.register(Font, true);


let SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ["9px","10px","11px" ,"12px","14px","16px" ,"18px","20px","22px","24px" , 

                       "26px"  , "28px",
                       "32px" , "36px" , "40px" , "44px" , "48px" , 
                       "54px" , "60px" , "66px" , "72px" , 
                        "80px" , "88px" , "96px"]

Quill.register(SizeStyle, true);

class MyEditComponent extends React.Component {
    constructor(props) {
        super(props);
       
      alert("myeditcomponent props is :" + JSON.stringify(this.props));
     alert("props is :" + JSON.stringify(this.props.children[1]));
      alert("props is 2:" + JSON.stringify(this.props));
        console.log("props value in MYEDITCOMPONENT "  + JSON.stringify(this.props) );
       //   alert("props value in MYEDITCOMPONENT bodyHtml"  + JSON.stringify(this.props.bodyHtml) );
      //   alert("props value in MYEDITCOMPONENT bodyHtml"  + JSON.stringify(this.props.children[0].bodyHtml) );
      //  console.log("props value in MYEDITCOMPONENT bodyhtml "  + JSON.stringify(this.props.children[0].bodyHtml) );
        this.state = { paramId: this.props.id};
         alert("construction props  id is :" + this.state.paramId);
          this.state = { paramUser: this.props.user };
           alert("construction props  user is :" + JSON.stringify(this.state.paramUser));
        this.state = { editorHtml: "" };
        this.state = { editorState: "" };
        this.state = {  imageURL: 'hello'};
      
         this.state = {  blogId :  ""}
         this.state = {  textTitle :  ""}
         
         
this.state = {  textCategory:  ""}
this.state = {imageDetails : ""}
this.state = {message : ""}
this.state = {block : ""}
this.state = {modal : ""}
this.state = {thumbnail : ""}			
this.state = {test : ""}	
this.state = {createdBy: ""}	
        
 this.state = {oldTitleImageName: ""}	
       
        
 this.state = {oldTitleVideoName: ""}	
 
  this.state = {oldPdfAttachName: ""}	
  
 this.state = {blogStatus: ""}	
 this.state = {blogStatusDate: ""}	
 
 
  this.state = {titleImage: ""}	
       
        
 this.state = {titleVideo: ""} 
 
         
 this.state = {pdfAttach: ""}   
    	
 this.state =	{selectedZfile1: ""}
 this.state =	{selectedZmp4file  : ""}
  this.state =	{selectedZpdfAttach  : ""}
  
  
 
 this.state =	{webURL : ""}
 this.state =	{webMP4URL  : ""}
 this.state =	{webPdfAttachURL  : ""}	
 
 this.state =	{pdfAttachInd  : true}	
 this.state =	{jpg1urlInd  : true}	 
 this.state =	{mp4urlInd  : true}	 
 
 
 this.state =	{status403  :false}
 
 this.state =	{status404  :false} 
 this.state =	{statusSuccess  :false} 
         
         
     
       //  console.log("myeditcomponent props blogData is :" + JSON.Stringify(this.props.blogData));
      //  alert("myeditcomponent props blogData is :" + JSON.Stringify(this.props.blogData));
        
      //  this.handleChange2 = this.handleChange2.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
         this.onCancel = this.onCancel.bind(this);
        this.onPublish = this.onPublish.bind(this);
        this.onUnPublish = this.onUnPublish.bind(this);
        
        this.changeTextTitle = this.changeTextTitle.bind(this);
        this.changeTextCategory = this.changeTextCategory.bind(this);
        this.loadProperty = this.loadProperty.bind(this);
               
          //     this.imageHandler = this.imageHandler.bind(this);
     //this.callpost1 = this.callpost1.bind(this);
     alert("constructor over");
    }


  componentDidMount() {
	alert("myEditcomponent - componentdidmount");
	 this.setState({ paramId: this.props.id});
    this.setState({ paramUser: this.props.user });
     alert("componentdidmount props  id 2 is :" + this.state.paramId);
        
    alert("componentdidmount props  user 2 is :" + JSON.stringify(this.state.paramUser));
	this.loadProperty();
	this.setState({ editorHtml: this.state.editorState});  
	}
 changeTextTitle = e => {
		// alert("in the change texttile")
  this.setState({textTitle: e.target.value} );
  }
changeTextCategory = e => {
	// alert("in the change textcategory")
	 this.setState({textCategory: e.target.value} );
  
  } 
  
onSubmit = async (e) => {
    e.preventDefault();
  try{

   alert("on Submit example")


    const formData = new FormData();
formData.append('id', this.state.blogId); 
formData.append('body', this.state.editorState ); 

formData.append('title', this.state.textTitle); 
formData.append('category', this.state.textCategory); 
             formData.append('id', this.state.blogId);
	
             if (this.state.selectedZfile1 !== null )
             {
			     formData.append('titleImage', this.state.selectedZfile1);
			       
			     }
			     
			
			     
			if (this.state.selectedZmp4file !== null )
             {
			     formData.append('titleVideo', this.state.selectedZmp4file);
              }   

			     
			if (this.state.selectedZpdfAttach !== null )
             {
			     formData.append('pdfAttach', this.state.selectedZpdfAttach);
              }   
              
              
          formData.append('status', "draft"); 






//alert("file name is :" + file.name );
//formData.append('name', file.name ) 
  alert("formData append1")
    

       alert("call back3")

       
      for (var pair of formData.entries())
       {
          console.log(pair[0]+ ', '+ pair[1]); 
          
          alert("check formData ENTRIES : for entry is : " + pair[0]   + ', '+ pair[1]);
        }

       
	alert("calling axios blogClient.writePost" );
	
	 const result = await editBlogClient.editSubmit2(formData  );
	 		
     alert("The blog modify result is :" + JSON.stringify(result));
 
      console.log("The blog modify result is :" + JSON.stringify(result));
      
      
       var jsonData = JSON.stringify(result);
	   var resultx = JSON.parse(jsonData);
	 
	 
   alert("NEWBLOGPOST result message  is :" + resultx.message);
  alert("NEWBLOGPOST result name  is :" + resultx.name);
    alert("NEWBLOGPOST result stack  is :" + resultx.stack);
   
   alert("NEWBLOGPOST result config is :" + JSON.stringify(resultx.config));
   alert("NEWBLOGPOST result method is :" + resultx.config.method);
     alert("NEWBLOGPOST result url  is :" + resultx.config.url);
     alert("NEWBLOGPOST result code  is :" + resultx.code);
       alert("NEWBLOGPOST result status is :" + resultx.status.toString());
     
  
      console.log("The blog modify result status  is :" + resultx.status);
            
  
      alert("The blog modify result status  is :" + resultx.status);
      
      
    //  if ( resultx.status == 403 ) {
	//this.setState({status403: true} );
	
	//}
      //  this.props.callbackfunc(resultx);
      // console.log("record updated" + JSON.stringify(result.data));
        this.setState(resultx.message )
        alert("The blog edit result message is :" + JSON.stringify(resultx.message));
        
        console.log("after edited  result param user in props JSON.stringify(this.state.paramUser) is :" + JSON.stringify(this.state.paramUser));
 /*      
       if (resultx.status === 201)
       {
	alert( "set to true success 201");
	 this.setState({statusSuccess: true} );
}
            if (resultx.status === 200)
       {
	 
	 alert( "set to true success 200");
	 this.setState({statusSuccess: true} );
}  

            if (resultx.status === 403)
       {
	 alert( "set to true success 403");
	 this.setState({status403: true} );
}  

            if (resultx.status === 404)
       {
	 this.setState({status404: true} );
}  

*/
     switch (resultx.status) {
	 case 200:
                 alert("handle navigate 200");
                 console.log("handle navigate 200");
                 this.setState({statusSuccess: true} );
                 break;
                 
    case 201:
                 alert("handle navigate 201");
                 console.log("handle navigate 201");
                 this.setState({statusSuccess: true} );
                 break;
    case 403:
                 alert("handle navigate 403");
                 console.log("handle navigate 403");
                 this.setState({status403: true} );
               
                 break;
                //handle route to the page
     case 404: 
            alert("handle navigate 404");
            this.setState({status404: true} );
               
             break;
      default :
           break;
         }
       
 /* 
       
        this.props.callbackfunc(result);
        
         
      if (!result) {
        console.log('No blog id  found')
        alert("No blog id  found: The property add result is :" + JSON.stringify(result));
        this.props.callbackfunc(result);
      }
      else {
        console.log("record updated" + JSON.stringify(result.data));
        this.setMessage(result.data.message )
        alert("The blog edit result message is :" + JSON.stringify(result.data.message));
        
        console.log("after edited  result param user in props JSON.stringify(this.state.paramUser) is :" + JSON.stringify(this.state.paramUser));
      //  this.props.callbackfunc(result);
      }
      
      
      */
    } catch (e) {
     // await toggleErrorAsync(e.message);
     alert("error in oneditsubmit" + e)
      return;
    }
	}
	 
 
onPublish = async (e) => {
    e.preventDefault();
  try{

   alert("on Publish example")


    const formData = new FormData();
formData.append('id', this.state.blogId); 
formData.append('body', this.state.editorState ); 

formData.append('title', this.state.textTitle); 
formData.append('category', this.state.textCategory); 
             formData.append('id', this.state.blogId);
	
             if (this.state.selectedZfile1 !== null )
             {
			     formData.append('titleImage', this.state.selectedZfile1);
			       
			     }
			     
			
			     
			if (this.state.selectedZmp4file !== null )
             {
			     formData.append('titleVideo', this.state.selectedZmp4file);
              }   

			     
			if (this.state.selectedZpdfAttach !== null )
             {
			     formData.append('pdfAttach', this.state.selectedZpdfAttach);
              }   
              
              
          formData.append('status', "published"); 





  alert("formData append1")
    

       alert("call back3")

       
      for (var pair of formData.entries())
       {
          console.log(pair[0]+ ', '+ pair[1]); 
          
          alert("check formData ENTRIES : for entry is : " + pair[0]   + ', '+ pair[1]);
        }

       
	alert("calling axios blogClient.writePost" );
	
	 const result = await editBlogClient.editSubmit2(formData  );
	 		
     alert("The blog modify result is :" + JSON.stringify(result));
 
      console.log("The blog modify result is :" + JSON.stringify(result));
      
      
       var jsonData = JSON.stringify(result);
	   var resultx = JSON.parse(jsonData);
	 
	 
   alert("NEWBLOGPOST result message  is :" + resultx.message);
  alert("NEWBLOGPOST result name  is :" + resultx.name);
    alert("NEWBLOGPOST result stack  is :" + resultx.stack);
   
   alert("NEWBLOGPOST result config is :" + JSON.stringify(resultx.config));
   alert("NEWBLOGPOST result method is :" + resultx.config.method);
     alert("NEWBLOGPOST result url  is :" + resultx.config.url);
     alert("NEWBLOGPOST result code  is :" + resultx.code);
       alert("NEWBLOGPOST result status is :" + resultx.status.toString());
     
  
      console.log("The blog modify result status  is :" + resultx.status);
            
  
      alert("The blog modify result status  is :" + resultx.status);
      

        this.setState(resultx.message )
        alert("The blog edit result message is :" + JSON.stringify(resultx.message));
        
        console.log("after edited  result param user in props JSON.stringify(this.state.paramUser) is :" + JSON.stringify(this.state.paramUser));
 
     switch (resultx.status) {
	 case 200:
                 alert("handle navigate 200");
                 console.log("handle navigate 200");
                 this.setState({statusSuccess: true} );
                 break;
                 
    case 201:
                 alert("handle navigate 201");
                 console.log("handle navigate 201");
                 this.setState({statusSuccess: true} );
                 break;
    case 403:
                 alert("handle navigate 403");
                 console.log("handle navigate 403");
                 this.setState({status403: true} );
               
                 break;
                //handle route to the page
     case 404: 
            alert("handle navigate 404");
            this.setState({status404: true} );
               
             break;
      default :
           break;
         }
       

    } catch (e) {
     // await toggleErrorAsync(e.message);
     alert("error in onPublish" + e)
      return;
    }
	}
	 
 
 
onUnPublish = async (e) => {
    e.preventDefault();
  try{

   alert("on UnPublish example")


    const formData = new FormData();
formData.append('id', this.state.blogId); 
formData.append('body', this.state.editorState ); 

formData.append('title', this.state.textTitle); 
formData.append('category', this.state.textCategory); 
             formData.append('id', this.state.blogId);
	
             if (this.state.selectedZfile1 !== null )
             {
			     formData.append('titleImage', this.state.selectedZfile1);
			       
			     }
			     
			
			     
			if (this.state.selectedZmp4file !== null )
             {
			     formData.append('titleVideo', this.state.selectedZmp4file);
              }   

			     
			if (this.state.selectedZpdfAttach !== null )
             {
			     formData.append('pdfAttach', this.state.selectedZpdfAttach);
              }   
              
              
          formData.append('status', "unpublished"); 





  alert("formData append1")
    

       alert("call back3")

       
      for (var pair of formData.entries())
       {
          console.log(pair[0]+ ', '+ pair[1]); 
          
          alert("check formData ENTRIES : for entry is : " + pair[0]   + ', '+ pair[1]);
        }

       
	alert("calling axios blogClient.writePost" );
	
	 const result = await editBlogClient.editSubmit2(formData  );
	 		
     alert("The blog modify result is :" + JSON.stringify(result));
 
      console.log("The blog modify result is :" + JSON.stringify(result));
      
      
       var jsonData = JSON.stringify(result);
	   var resultx = JSON.parse(jsonData);
	 
	 
   alert("NEWBLOGPOST result message  is :" + resultx.message);
  alert("NEWBLOGPOST result name  is :" + resultx.name);
    alert("NEWBLOGPOST result stack  is :" + resultx.stack);
   
   alert("NEWBLOGPOST result config is :" + JSON.stringify(resultx.config));
   alert("NEWBLOGPOST result method is :" + resultx.config.method);
     alert("NEWBLOGPOST result url  is :" + resultx.config.url);
     alert("NEWBLOGPOST result code  is :" + resultx.code);
       alert("NEWBLOGPOST result status is :" + resultx.status.toString());
     
  
      console.log("The blog modify result status  is :" + resultx.status);
            
  
      alert("The blog modify result status  is :" + resultx.status);
      

        this.setState(resultx.message )
        alert("The blog edit result message is :" + JSON.stringify(resultx.message));
        
        console.log("after edited  result param user in props JSON.stringify(this.state.paramUser) is :" + JSON.stringify(this.state.paramUser));
 
     switch (resultx.status) {
	 case 200:
                 alert("handle navigate 200");
                 console.log("handle navigate 200");
                 this.setState({statusSuccess: true} );
                 break;
                 
    case 201:
                 alert("handle navigate 201");
                 console.log("handle navigate 201");
                 this.setState({statusSuccess: true} );
                 break;
    case 403:
                 alert("handle navigate 403");
                 console.log("handle navigate 403");
                 this.setState({status403: true} );
               
                 break;
                //handle route to the page
     case 404: 
            alert("handle navigate 404");
            this.setState({status404: true} );
               
             break;
      default :
           break;
         }
       

    } catch (e) {
     // await toggleErrorAsync(e.message);
     alert("error in onUnPublish" + e)
      return;
    }
	}


 
  
   onCancel = () => {
   alert("CANCEL BUTTOM DONE")
  }
  
  /*
	    onEditSubmit2 = async (e) => {
		e.preventDefault();
		alert("Submit 2 function")
		
		alert("content body editorState is: added to formData for update:" + this.state.editorState);
		alert("content body editorHtml  is:" + this.state.editorHtml);
		
		try {	
  
		
		const formData = new FormData();
             formData.append('id', this.state.blogId);
	
             if (this.state.selectedZfile1 !== null )
             {
			     formData.append('titleImage', this.state.selectedZfile1);
			       
			     }
			     
			
			     
			if (this.state.selectedZmp4file !== null )
             {
			     formData.append('titleVideo', this.state.selectedZmp4file);
              }     
			
      


formData.append('body', this.state.editorState ); 

formData.append('title', this.state.textTitle); 
formData.append('category', this.state.textCategory); 
formData.append('id', this.state.blogId); 


formData.append('status', this.state.status); 




			console.log("Upload form data is below 3");
			console.log(...formData);
                const result = editBlogClient.rewritePost(formData  ); 
			
		

     // console.log("The property modify result is :" + JSON.stringify(result));
     //    alert("The property modify result is :" + JSON.stringify(result));
   
      if (!result) {
        console.log('No property id  found')
        alert("No property id  found: The property add result is null");
      }
      else {
        console.log("record updated" + JSON.stringify(result.data));
          alert("record updated" + JSON.stringify(result.data));
        alert("The property edit result message is :" + JSON.stringify(result.data.id));
        this.setMessage(result.data.message )
        alert("The property edit result message is :" + JSON.stringify(result.data.message));
        console.log("after edited  message is is :" + this.state.message);
        console.log("after edited  result param user in props JSON.stringify(this.state.paramUser) is :" + JSON.stringify(this.state.paramUser));
      
      }
    } catch (ex) {
     // await toggleErrorAsync(e.message);
     alert("error in oneditsubmit")
      return;
    }
	}
	
	*/
loadProperty = ( ) => {
     alert("in the loadproperty id to retrieve is : " + this.state.paramId);    
    alert("calling  showBlogClient.getBlogById  id is : " + this.state.paramId);   
    showBlogClient.getBlogById(this.props.id)
      .then((res) => {
        let blogData = res.data;
        alert("data returned fetch is:" + res.data);
        

        
        this.setState({editorState:blogData.body});
        this.setState({blogId:blogData.id});
       this.setState({textTitle:blogData.title});
      this.setState({textCategory:blogData.category});
      
      this.setState({oldTitleImageName:blogData.titleImageName});
         this.setState({oldTitleVideoName:blogData.titleVideoName});
         
            this.setState({oldPdfAttachName:blogData.pdfAttachName});
         
       this.setState({blogStatus:blogData.blogStatus});
      
 this.setState({blogStatusDate:blogData.blogStatusDate});
 this.setState({createdBy: blogData.createdBy});
	

         alert("old title image name :" + blogData.titleImageName);

	 alert("old title video name :" + blogData.titleVideoName);
	 
	  alert("old pdf Attach  name :" + blogData.pdfAttachName);
   
         alert("body for quill is useeffect editorState:" + this.state.editorState);
         
         
        
   {/*    
    const jpg1url = BLOG_MEDIA_BASE_URL + "/blogDBimages/" + blogData.id + "/" + blogData.titleImageName;
    
    const mp4url = BLOG_MEDIA_BASE_URL + "/blogDBvideo/stream/mp4/" + blogData.id 
                                                                           + "/" 
                                                                   + blogData.titleVideoName;
  */}  
  
       
     let  jpg1url = "";  
      if (blogData.titleImageName === null )
          {
	       this.setState({jpg1urlInd : false} ); 

	        jpg1url  = null ;

          } 
           
      if (blogData.titleImageName !== null )
         
          {
	     this.setState({jpg1urlInd : true} );    
	     jpg1url = BLOG_MEDIA_BASE_URL + "/blogDBimages/" + blogData.id + "/" + blogData.titleImageName;
	    
                                                                          
         } 
               
          let  mp4url = "";  
      if (blogData.titleVideoName === null )
          {
	       this.setState({mp4urlInd : false} ); 

	        mp4url  = null ;

          } 
           
      if (blogData.titleVideoName  !== null )
         
          {
	     this.setState({mp4urlInd : true} );    
	    
	     mp4url = BLOG_MEDIA_BASE_URL + "/blogDBvideo/stream/mp4/" + blogData.id 
                                                                           + "/" 
                                                                       + blogData.titleVideoName;
                                                                          
         } 
                               
               
                        

             let  pdfAttachurl = "";  
                                                                           
          if (blogData.pdfAttachName === null )
          {
	this.setState({pdfAttachInd : false} ); 
	 pdfAttachurl = null ;
	 
	
          }                                                                           
                                                                          
                                                                           
          if (blogData.pdfAttachName !== null )
         
          {
	     this.setState({pdfAttachInd : true} );    
	
	     pdfAttachurl =
	        BLOG_MEDIA_BASE_URL +  "/blogDBpdf/stream/blog3/" + blogData.id 
                                                                           + "/" 
                                                                           + blogData.pdfAttachName;  
         } 
                                                                 
    
   
     
                                                 
                                                                 
      this.setState({webURL :jpg1url});

     this.setState({webMP4URL :mp4url});
     
   this.setState({webPdfAttachURL :pdfAttachurl});
  
      });

};
  
/*  added 20230111   */
/*
     handleChange2(html, delta, source, quill)   {
	   this.quill.focus();
        this.setState({ editorHtml: html });
            
     this.setState({ editorState: html });
     //  alert("editor child html os : " +  this.state.editorHtml);
      this.quill.selection.index = delta.length     + 1;
    }
   */ 
    /* 20230111 change 
handleChange2(html, delta, source, quill)  {
	console.log("handleChange2");
	  console.log("before  index is :" + this.quill.selection.index); 
    console.log("QUILL GETHTML is :" + quill.getHTML() +":"); // html 사용시
    console.log("get contents is :" + JSON.stringify( quill.getContents())+":"); // delta 사용시
  
   console.log("html is :" + JSON.stringify(html) + ":"); 
    console.log("delta  is :" + JSON.stringify(delta)+":"); 
     console.log("source  is :" + JSON.stringify(source) + ":"); 
     
    console.log("value is :" +  this.quill.value +":");
    this.quill.focus();
     console.log("html is :" +  JSON.stringify(html) + ":");
    console.log("quill.state   is :" +  JSON.stringify(this.quill.state) + ":");
   console.log("selection  is :" +   JSON.stringify(this.quill.selection) + ":");
     this.setState({ editorHtml: html });
     this.setState({ editorState: html });
       this.quill.selection.index = delta.length     + 1;
       console.log("after index is :" + this.quill.selection.index); 
     console.log("handle change done"); 
  };
  */
  
  
  
 /* 
handleChange2(
 html, 
    	delta, 
    	source, 
    	quill){
	
	      var range1 = quill.getSelection();
	       console.log("RANGE1  : insert delta2 : RANGE   is " + JSON.stringify(range1 ) ); 
	     
       console.log("RANGE1  : insert delta2 : RANGE   is " + JSON.stringify(range1 ) );     
    alert("before length  is " + quill.getLength());
    console.log("before  length  is " + quill.getLength());
alert("quill get content json stringify :" +  JSON.stringify(quill.getContents()) );
console.log("quill get content json stringify  :" +  JSON.stringify(quill.getContents()) );
console.log("quill get content :" +  quill.getContents() );
alert("delta JSON.STRINGIFY :" +  JSON.stringify(delta) );
console.log("source is json.stringify" +  JSON.stringify(source) );


 //const selection = quill.getSelection();
  // quill.insertText(selection.index, html);
alert("quill get content :" +  quill.getContents() );
console.log("quill get content :" +  quill.getContents() );


        this.setState({ editorHtml: html });
     this.setState({ editorState: html });
     
    alert("after length  is " + quill.getLength());
    console.log("after length  is " + quill.getLength());
    console.log("QUILL.GETselection before   is " +  JSON.stringify(quill.getSelection()));
     console.log("QUILL.STRINGIFY    is " +  JSON.stringify(quill));
    var delta2 = quill.getContents();
    
      console.log("content delta2 is" + JSON.stringify(delta2));
      console.log("DELTA2 LENGTHB IS :" + delta2.length);
    // added on 2022/12/19 to remove reverse typing 
      
    
     
  
  
    console.log("after setContents : insert delta2 : length  is " + quill.getLength());
    alert ("after setContents : insert delta2 : length  is " + quill.getLength());
 
    
      console.log("after setSelection  : + 1 :  length  is " + quill.getLength());
    alert ("after setContents : +1 :  : length  is " + quill.getLength());
    
    // console.log("RANGE2  : insert delta2 : RANGE 2  is " + JSON.stringify(range2 ) );    
    // console.log("AFTER LAST SETSELECTION : QUILL.GETselection after   is " +  JSON.stringify(quill.getSelection()));
        if (quill.getSelection()  !== null  ){
        
         quill.setSelection(range1.index + 1 , 1);   
         } 
    }

*/
/*
    imageHandler () {
	    alert("imge handler ") 
	    
	    
	    var Image = Quill.import('formats/image');
Image.className = 'imgClass';
Quill.register(Image, true);
	
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
      
           input.setAttribute('accept', 'image/*');
        input.click();


        input.onchange = async () => {
            const file = input.files[0];
            alert("file is : " + file );
            const formData = new FormData();

            formData.append('upload', file);

            // Save current cursor state
            const range = this.quill.getSelection(true);

            // Insert temporary loading placeholder image
          //  this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);
   //   this.quill.insertEmbed(range.index, 'image', `http://localhost:8080/api/showme/image.jpg`);
            // Move cursor to right side of image (easier to continue typing)
            this.quill.setSelection(range.index + 1);

            //const res = await apiPostNewsImage(formData); // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
             console.log("IMPORTANT calling callpost form data ");
             
             
              const  restURL =  await myEditComponentClient.callpost(formData)
               alert("restURL :" + restURL); 
            // Remove placeholder image
            
            
        //  this.quill.deleteText(range.index, 1);


            
            
            this.quill.insertEmbed(range.index, 'image', restURL);
        };
        
        
      
         this.quill.setSelection(this.quill.getSelection().index + 1);
    };
   */       
       
    mp4Handler () { 

var video = Quill.import('formats/video');
video.className = 'videoClass';
Quill.register(video, true);

        const input = document.createElement('input');
        input.setAttribute('type', 'file');
  // input.setAttribute('accept', '*/*');
  //     input.setAttribute('accept', "video/mp4,video/mkv,video-m4v,video/*");
  
              input.setAttribute('accept', "video/mp4");
      input.click();


     
        input.onchange = async () => {
	   alert("mp4 handler :  input.onchange ");
	   console.log("mp4 handler :  video.onchange ");
	   
	  const file = input.files[0];
	   const formData = new FormData();

       formData.append('namez', file.name);
       console.log("file name is :  " + file.name);
	   formData.append("file", file );
	   
	   const buffer =  file.arrayBuffer();
    let byteArray = new Int8Array(buffer);
    console.log(byteArray)
    alert("bytearray:" + byteArray);
  
    
  

   var object = {};
formData.forEach((value, key) => object[key] = value);
var json = JSON.stringify(object);
console.log(json);
alert(json);

	      //  alert ("FILE IS :"  +  JSON.stringify(file))
          
           // alert("file is : " + file );
          
            // Save current cursor state
            const range = this.quill.getSelection(true);

            // Insert temporary loading placeholder image
          //  this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);
     // this.quill.insertEmbed(range.index, 'image', `http://localhost:8080/api/showme/image.jpg`);
            // Move cursor to right side of image (easier to continue typing)
            this.quill.setSelection(range.index + 1);

            //const res = await apiPostNewsImage(formData); // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
             console.log("IMPORTANT calling callpost form data ");
              
 alert("IMPORTANT calling callpost form data myComponentClient2.callm");
             
              const  restURL =  await myComponentClient2.callmp4post(formData)
               alert("returned restURL :" + JSON.stringify(restURL)); 
              console.log("returned restURL :" + JSON.stringify(restURL)); 
            // Remove placeholder image
            //this.quill.deleteText(range.index, 1);


            
            
            this.quill.insertEmbed(range.index, 'video', restURL.data ,  'user');
      };
          alert("after input mp4 change");
    };
          
     
     
     
     
     /*
     handlers: {
                                image: this.imageHandler
                            }

*/
  createMarkup = (html) => {
    return  {
      __html: DOMPurify.sanitize(html)  
      
      //  __html: html
      
    }
  }	
  
  
  
  
    render() {
console.log("my component render id is "  + this.state.paramId); 

 
	let button;
         
         
       if (this.state.pdfAttachInd)        
 
     {
  button =    <a href= {this.state.webPdfAttachURL} download="Article PDF " target='_blank'>
    <button type="button" class="btn btn-success btn-lg btn-block">Download Article PDF</button>
    </a>
    }
   
     let mp4TitleVideo;
     
	   if ( this.state.mp4urlInd )
	 {
	   mp4TitleVideo  =       <div style={{ display: 'flex' , justifyContent:'center', alignItems:'center'}}>
        <p> React player </p>
     {/*   width="25vw"
      height="25vh"
      
      */}
        <ReactPlayer className='react-player' 
        url={this.state.webMP4URL} 
        controls={true}  
        
             
        />
        </div>
	 }
	 
    
     let jpg1TitleImage;
    /*  className="title-image"  */
	   if ( this.state.jpg1urlInd )
    {
       jpg1TitleImage =            <img
                className="title-responsive"
                src={this.state.webURL}
             
                alt="Title Image"
                
               
              />
              }
        return (
            <div className="text-editor">
            
     <Form.Label htmlFor="inputPassword5"   style={{fontSize: 20}}>Post Id</Form.Label>
      <Form.Control  style={{fontSize: 20}}
        type="number"
        id="PostID"
        aria-describedby="titleHelp"
        value={this.props.id}
        readOnly
      />

      
      
      <Form.Label htmlFor="inputPassword5"  style={{fontSize: 20}}>Title</Form.Label>
      <Form.Control  style={{fontSize: 20}}
        as="textarea" rows={3} 
        id="textTitle"
        aria-describedby="titleHelp"
        value={this.state.textTitle}
        onChange={this.changeTextTitle}
      />

      <Form.Text id="titleHelp" muted>
      please give Title Help 
      </Form.Text>
      
            
        <Form.Label htmlFor="category" style={{fontSize: 20}}>Category</Form.Label>
        <Form.Control
          as="select"
          value={this.state.textCategory}  style={{fontSize: 20}}
          onChange={this.changeTextCategory}
          >
        <option value="Healthcare">Healthcare</option>
        <option value="Management">Management</option>
         <option value="Engineering">Engineering</option>
          <option value="Education">Education</option>
          <option value="SMSE">Small Enterprises</option>
          <option value="MSME">Medium and Small Enterprises</option>
           <option value="OTHER">OTHER</option>
        </Form.Control>
         <Form.Text id="categoryHelp" muted>
      please Select Article Category 
      </Form.Text>
     
      
      {/*
     <Form.Label htmlFor="category"  style={{fontSize: 20}}>Category</Form.Label>
      <Form.Control style={{fontSize: 20}}
        type="text" 
        id="textCategory"
        aria-describedby="categoryHelp"
         value={this.state.textCategory}
        onChange={this.changeTextCategory}
      />
         
         
         
         
         
      <Form.Text id="categoryHelp" muted>
      please give Article Category 
      </Form.Text> 
      */}
      
      
      
 <Table striped bordered hover responsive="md">
                            <thead>
                                <tr>
                                 Blog Article  
                                   
                                </tr>
                            </thead>
                            <tbody>
      


       {/*
      <tr>
          <td>
        <div style={{ display: 'flex' , justifyContent:'center', alignItems:'center'}}>
        
        <p> React player </p>
          <p> WEBURL : {this.state.webURL} </p>
      <p> WEBMP4URL : {this.state.webMP4URL} </p>
    
      <video width="400" controls>
  <source src={this.state.webMP4URL} type="video/mp4"/>
  
  Your browser does not support HTML video.
</video>

        <ReactPlayer className='react-player' 
        url={this.state.webMP4URL} 
        controls={true}  width='50%' height='50%' />
    

        </div>
         </td>
         </tr>
         
         
         */}
            <tr>
          <td>
          {mp4TitleVideo}
          
          {/*
        <div style={{ display: 'flex' , justifyContent:'center', alignItems:'center'}}>
        <p> React player </p>
     {/   width="25vw"
      height="25vh"
      
      /}
        <ReactPlayer className='react-player' 
        url={this.state.webMP4URL} 
        controls={true}  
        
             
        />
        </div>
        */}
         </td>

 {/* width='25%' height='25%'  */}

  </tr>
  
  {/*  url="http://localhost:9090/blogDBvideo/stream/mp4/1/test1.mp4"  */}
  
  
        <tr>

          <td>
              {jpg1TitleImage}
              {/*
              <img
                className="title-image"
                src={this.state.webURL}
             
                alt="Title Image"
                
               
              />
              */}
 </td>


  </tr>
        
         </tbody>
                        </Table> 
                        
      <p> Image File : {this.state.oldTitleImageName} </p>
      <p> video  File : {this.state.oldTitleVideoName} </p>
                <p>Pdf Attach File : {this.state.oldPdfAttachName} </p>              
      <p> WEBURL : {this.state.webURL} </p>
      <p> WEBMP4URL : {this.state.webMP4URL} </p>
        <p> pdfAttach URL  : {this.state.webPdfAttachURL} </p>
                <p> pdf attachment url for Download </p>

         
       {this.state.webPdfAttachURL}  
         {button}

      							<Form.Group  >
								<Form.Label style={{fontSize: 20}}>Title Image:</Form.Label>

								<Form.Control  style={{fontSize: 20}}
									type="file"
									name="selectedZfile1"
								
									placeholder="enter Title Image"
									onChange={(e) =>  this.setState({selectedZfile1: e.target.files[0]} )}
									
								
								/>

							</Form.Group>
						
							<Form.Group  >
								<Form.Label style={{fontSize: 20}}>Title Video :</Form.Label>

								<Form.Control  style={{fontSize: 20}}
									type="file"
									name="selectedZmp4file"
								
									placeholder="enter property Property mp4 video"
									
										onChange={(e) =>  this.setState({selectedZmp4file: e.target.files[0]} )}
									
								
									
									
								/>

							</Form.Group>
							
							
							<Form.Group  >
								<Form.Label style={{fontSize: 20}}>Attach Pdf :</Form.Label>

								<Form.Control  style={{fontSize: 20}}
									type="file"
									name="selectedZpdfAttach"
								
									placeholder="enter PDF Attachment "
									
										onChange={(e) =>  this.setState({selectedZpdfAttach: e.target.files[0]} )}
									
								
									
									
								/>

							</Form.Group>
       {/* video: this.mp4Handler */}
                <span style={{fontSize: '20px'}}>        
                  <MyEditor
        handleChange={(data) => { 
	   console.log("data returned is :" + data );   
     // alert()
          this.setState({ editorHtml: data }); 
           this.setState({ editorState : data }); 
          //this.props.parentCallback(data); 
        }}
        data={this.state.editorState}
        {...this.props}
      />
       </span>
      <p> {JSON.stringify(this.state.editorHtml)}</p>
       
       {/*
       
       
         <p> editor data    editorHTML is :  {JSON.stringify(this.props)}</p> 
           {JSON.stringify(this.props)};
                <hr />
                <ReactQuill
                    ref={el => {
                        this.quill = el;
                    }}
                    value={this.state.editorState}  
                    onChange={this.handleChange2}
                    placeholder={this.props.placeholder}
                   
                    modules={{
                        toolbar: {
                            container: [
                                [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, 
                                
                                    { 'font': Font.whitelist }],
                               
                                 [{'size': SizeStyle.whitelist}],  
                                 
                                 [{preserveWhiteSpace: true}],

                                ['bold', 'italic', 'underline', 'blockquote'],
                                [{ list: 'ordered' }, { list: 'bullet' } ,{indent :'+1'} , {indent : '-1'}  ],
                                
                                [{ 'color': [] }, { 'background': [] }],
                                
                     [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                             
                               
                                ['link', 'image', 'video'],
                               ['clean'],
                                ['code-block', 'formula'],
                                [{ 'script': 'sub'}, { 'script': 'super' }]
                            ],           
                                
						
                             handlers: {
                                image: this.imageHandler,
                               
                            }
                        }
                    }}
                />
           */}     
                      <p style={{fontSize: 20}}> Status : {this.state.blogStatus} </p>
      <p style={{fontSize: 20}}> Status Date: {this.state.blogStatusDate} </p>
              {/* <div className="preview" dangerouslySetInnerHTML={this.createMarkup(convertedContent)}></div> */}
                <Button style={{fontSize: 20}} color='info'  onClick={this.onSubmit}>save</Button>
        <Button style={{fontSize: 20}} color='danger'  onClick={this.onCancel}>cancel</Button>
        <Button style={{fontSize: 20}} color='danger'  onClick={this.onPublish}>Publish</Button>
        <Button style={{fontSize: 20}} color='danger'  onClick={this.onUnPublish}>UnPublish</Button>
        
       {this.state.status403 && (
          <Navigate to="/page403"  />
        )}      
        
         {this.state.status404 && (
          <Navigate to="/page404"  />
        )}
       {this.state.statusSuccess && (
          <Navigate to="/success200"  />
        )}
        
        </div>
        )
    }
}
export default MyEditComponent;


