import { Link } from "react-router-dom";
// import img from './errorImage.jpg';
import './Success200.css';

//  <div style={{ backgroundImage: "url(/errorImage403.jpg)" } }> 
// 	<div style={{ backgroundImage: "url(/success200.jpg)" } }> */} 


export default function Success200(props) {
  return (

      <div className="background-green">    
     	

       <div  className="centered">
       
       
       <h1>
    <p>Article has been Posted Successfully </p>
    
    <br></br>
    
    <p>Here are some helpful links:</p>
    <br></br>
    <p><Link to='/'>Home</Link></p>
    
    
   </h1>


    

    </div>


   </div>



  )
}