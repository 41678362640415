import React, { useState } from 'react';
//import { EditorState } from 'draft-js';
import { useNavigate } from "react-router-dom";

//import axios from "axios";
//import { convertToHTML } from 'draft-convert';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//import { EditorState, convertToRaw, ContentState } from 'draft-js';
//import { Editor } from 'react-draft-wysiwyg';

// import draftToHtml from 'draftjs-to-html';

//import htmlToDraft from 'html-to-draftjs';


import DOMPurify from 'dompurify';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './NewBlogPost.css';

import { Form , Button } from 'react-bootstrap';
import BlogPostClient from '../BlogPostClient';
// import BlogClient from '../BlogClient';
import MyComponent from './MyComponent';
//import  './MyComponent.css';

// ref : https://blog.logrocket.com/building-rich-text-editors-in-react-using-draft-js-and-react-draft-wysiwyg/


const NewBlogPost = () => {
	
const navigate = useNavigate();
const [blogId , setBlogId]  = useState( null );
const [ textTitle , setTextTitle] = useState( '');
const [ textCategory , setTextCategory] = useState( 'Healthcare');
  
	
	const [titleImage, setTitleImage] =  useState({});
 	const [titleVideo , setTitleVideo ] =  useState({});
 	const [pdfAttach , setPdfAttach ] =  useState({});

const [ imageDetails , setImageDetails] = useState( '');
const [message , setMessage] =  useState( '');
const [block , setBlock] = useState(false)	;
const [modal , setModal] = 	useState(false)	;			
const [thumbnail, setThumbnail] = useState();

// const [files, setFiles] = useState([]);

				
  const [editorState, setEditorState] = useState(
    "",
  );
 /*
const createInit =     () => EditorState.createEmpty();

  
    const [editorState, setEditorState] = useState(
    createInit
  );
  */
  const  [convertedContent, setConvertedContent] = useState(null);
  
  
  const  [ quillHTML, setQuillHTML] = useState(null);
  
  const handleEditorChange = (state) => {
    setEditorState(state);
   // convertContentToHTML();
  }
/*
  const convertContentToHTML = () => {
	  
 //   const title = textTitle;
    const body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
	  
	  
  //  let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
   // setConvertedContent(currentContentAsHTML);
    
      setConvertedContent(body);
     
  }
*/
  const createMarkup = (html) => {
    return  {
      __html: DOMPurify.sanitize(html)  
      
      //  __html: html
      
    }
  }
  
 const   changeTextTitle = e => {
    setTextTitle( e.target.value );
  }
   const   changeTextCategory = e => {
    setTextCategory( e.target.value );
  }
  
 const  onSubmit = async (e) => {
    e.preventDefault();
  try{

   // *** alert("on Submit example")


    const formData = new FormData();

formData.append('body', quillHTML ); 

formData.append('title', textTitle); 

formData.append('category', textCategory); 


formData.append('titleImage', titleImage); 
formData.append('titleVideo' , titleVideo );
formData.append('pdfAttach' , pdfAttach);

formData.append('id', null); 
//alert("file name is :" + file.name );
//formData.append('name', file.name ) 
  // *** alert("formData append1")
    

     // ***  alert("call back3")

       
      for (var pair of formData.entries())
       {
          console.log(pair[0]+ ', '+ pair[1]); 
          
         // ***  alert("for entry is : " + pair[0]   + ', '+ pair[1]);
        }

       
// ***	alert("calling axios blogPostClient");
	 const result =  await   BlogPostClient(formData);
	 
	 // -----------------------------------------------------------------------
     // unclear why my result object status is not accessible , so 
	 // first converted to JSON string and then back to Javascript object 
	 // then it is accessible properly 
	 // ------------------------------------------------------------------------------
	 
	 
	 var jsonData = JSON.stringify(result);
	 var resultx = JSON.parse(jsonData);
  // ***  alert("NEWBLOGPOST result message  is :" + resultx.message);
  // *** alert("NEWBLOGPOST result name  is :" + resultx.name);
  // ***   alert("NEWBLOGPOST result stack  is :" + resultx.stack);
   
   // *** alert("NEWBLOGPOST result config is :" + JSON.stringify(resultx.config));
   // *** alert("NEWBLOGPOST result method is :" + resultx.config.method);
   // ***  alert("NEWBLOGPOST result url  is :" + resultx.config.url);
    // ***  alert("NEWBLOGPOST result code  is :" + resultx.code);
     // ***   alert("NEWBLOGPOST result status is :" + resultx.status.toString());
     
	 /*
  //'content-type': 'multipart/form-data',
  const response = await axios.post('http://localhost:8080/api/postBlog',  formData,
       {
                    headers: { 
                     "Content-Type": `multipart/form-data: boundary=add-random-characters`,
 
                         'Access-Control-Allow-Origin' : '*',
                        
                    }, 
                    
                  
         })
         
         */ 
  // ***  alert("NEWBLOGPOST response is :" + JSON.stringify(resultx));
    console.log ("NEWBLOGPOST response is :" + JSON.stringify(resultx));
    /*
     if (result.response) {
      // Request made and server responded
      console.log(result.data);
      console.log(result.response.status);
      console.log(result.response.headers);
      alert("NEWBLOGPOST :" + result.data);
      alert("NEWBLOGPOST :" + result.status);
      alert("NEWBLOGPOST :" + result.headers);
      
      
      alert ("NEW BLOGPOST error response data :" + result.response.data);
      alert("NEWBLOGPOST error response status " + result.response.status);
      console.log("NEW BLOG POST error response headers :" + result.response.headers);
    } else if (result.request) {
      // The request was made but no response was received
      console.log(result.request);
     alert ("error resuest message " + result.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', result.message);
      alert("NEW BLOGPOST Error message is :" +result.message);
    }
   */
        // Error
        
        
     // ***    alert("NEWBLOGPOST result status  is :" + resultx.status);
   console.log("NEWBLOGPOST result status  is :" + resultx.status);
        switch (resultx.status) {

	  case 200:
              // ***   alert("success status 200");
                 console.log("success status 200");
                 navigate("/success200");
                 break;
                //handle route to the page
                
       case 201:
                 alert("success status 201");
                 console.log("success status 201");
                 navigate("/success200");
                 break;
                //handle route to the page
            case 403:
                 alert("handle navigate 403");
                 console.log("handle navigate 403");
                 navigate("/page403");
                 break;
                //handle route to the page
            case 404: 
            alert("handle navigate 404");
                navigate("/page404");
                break;
           default :
           break;
         }
    
}
catch (ex){
   alert ("NEWBLOGPOST error is :" + ex)
   console.log ("NEW BLOG POST error is :" + ex)
   navigate("/page404");
   
   
 }
}

 
  
  const  onCancel = () => {
   alert("CANCEL BUTTOM DONE")
  }
  
  
 const     handleCallback = (quillHTML) =>{
	
	   // alert("returned from child with HTML :" + quillHTML);
	    setQuillHTML(quillHTML);
     //   alert("AFTER SETTING ADD BLOG HTML DATA IS :" + quillHTML);
    }
return (
    <div>
    {/* <div className="App">  */}
   {/*   type="text"  */}
   <Form.Group   >
      <Form.Label  style={{fontSize: 20}}>Title</Form.Label>
      <Form.Control    style={{fontSize: 20}}
        as="textarea" rows={3} 
        id="textTitle"
        aria-describedby="titleHelp"
        onChange={changeTextTitle}
      />

     
      <Form.Text id="titleHelp"  muted>
      please give Title Help 
      </Form.Text>
       </Form.Group> 
       
       
      {/*     <Form.Control
        type="text"   style={{fontSize: 20}}
        id="textTitle"
        aria-describedby="categoryHelp"
        onChange={changeTextCategory}
      />
      <Form.Text id="categoryHelp" muted>
      please give Article Category 
      </Form.Text>
      
      </Form.Group> 
      
      */}
      
       <Form.Group controlId="formBasicSelect">
        <Form.Label htmlFor="category" style={{fontSize: 20}}>Category</Form.Label>
        <Form.Control
          as="select"
          value={textCategory}  style={{fontSize: 20}}
          onChange={changeTextCategory}
          >
            
        <option value="Healthcare">Healthcare</option>
        <option value="Management">Management</option>
         <option value="Engineering">Engineering</option>
          <option value="Education">Education</option>
          <option value="SMSE">Small Enterprises</option>
          <option value="MSME">Medium and Small Enterprises</option>
           <option value="OTHER">OTHER</option>
        </Form.Control>
         <Form.Text id="categoryHelp" muted>
      please Select Article Category 
      </Form.Text>
      </Form.Group>
            
    
      
      <header className="App-header">
        Rich Text Editor Example
      </header>
							<Form.Group    >
								<Form.Label  style={{fontSize: 20}}>Title Image:</Form.Label>

								<Form.Control  style={{fontSize: 20}}
									type="file"
									name="titleImage"
								
									placeholder="enter Title Image"
									onChange={(e) => setTitleImage(e.target.files[0])}
								/>

							</Form.Group>
						
							<Form.Group  >
								<Form.Label  style={{fontSize: 20}}>Title Video :</Form.Label>

								<Form.Control  style={{fontSize: 20}}
									type="file"
									name="titleVideo"
								
									placeholder="enter property Property mp4 video"
									onChange={(e) => setTitleVideo(e.target.files[0])}
								/>

							</Form.Group>
							
														<Form.Group  >
								<Form.Label  style={{fontSize: 20}}>Pdf Attachment :</Form.Label>

								<Form.Control  style={{fontSize: 20}}
									type="file"
									name="pdfAttach"
								
									placeholder="Enter Pdf Attachment File"
									onChange={(e) => setPdfAttach(e.target.files[0])}
								/>

							</Form.Group>
      <MyComponent    parentCallback = {handleCallback}> </MyComponent>

        <Button  variant="primary" size="lg"  onClick={onSubmit}>save</Button>
        <Button  variant="secondary" size="lg"   onClick={onCancel}>cancel</Button>
       
	     <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div> 

   
    </div>
  )
};

export default NewBlogPost
