import { Link } from "react-router-dom";
import img from './errorImage.jpg';
import './Page403.css';

//  <div style={{ backgroundImage: "url(/errorImage403.jpg)" } }> 

export default function Page403() {
  return (
	
	<div style={{ backgroundImage: "url(/errorImage403.jpg)" } }>
{/*	<div className="background-red">   */}

       <div  className="centered">
       
       
       <h1>
    <p>Oops Error Code 403 ! UserID Not Authorized for this Activity.</p>
    
    <br></br>
    
    <p>Here are some helpful links:</p>
    <br></br>
    <p><Link to='/'>Home</Link></p>
    
    
   </h1>


    

    </div>


   





















    </div>
  )
}