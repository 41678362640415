import React, { useState  , useRef  , useEffect } from 'react';
//import jsPDF from 'jspdf';
//import domtoimage from 'dom-to-image';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { Table ,  ButtonGroup,  Button } from 'react-bootstrap';


import { useNavigate,  useLocation } from "react-router-dom";

import showBlogClient   from "./showBlogClient";
import ReactQuill from 'react-quill';
import '../../node_modules/react-quill/dist/quill.bubble.css';


import ReactPlayer from 'react-player';

import 'bootstrap/dist/css/bootstrap.min.css';


import "./ShowBlog.css";

import "../quillImageVideoCSS/QuillImageVideoCSS.css";
import Comments from '../comments/Comments';
// import { useReactToPrint } from "react-to-print";

import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../constants';
const ShowBlog = () => {
   // ***  alert("in the showBlog  to get asis db data");
  const componentRef = useRef();

   
    
  	const navigate = useNavigate();
  	
  	const location = useLocation();

  	let [paramId , setParamId] = useState();
    let [paramUser , setParamUser] = useState()

    let [webTitleImageURL , setWebTitleImageURL]  = useState();
  	
   let [webTitleVideoURL , setWebTitleVideoURL]  = useState();
  	
  	
   let [webPdfAttachURL , setWebPdfAttachURL]  = useState();
 
 
    let [pdfAttachInd , setPdfAttachInd ] = useState(true);
    
     let [jpg1urlInd ,  setJpg1urlInd ] = useState(true);
        let [mp4urlInd ,  setMp4urlInd ] = useState(true);
    
    
	let [blogData, setBlogData] = useState(
		{
  
      id: '',
      title: '',
      category: '',
      body: '',
       createdBy: '',  
       
       

    
 titleImageName:'',





   titleVideoName:'',
   
   pdfAttachName:'',
   
    status :'',
    
    statusDate : ''   
       
    })
 
 /*  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
 */   
  useEffect(( ) => {
	
	
	const loadProperty = ( ) => {
    // ***   alert("in the useEffect");    
    setParamUser(location.state.paramUser)
    showBlogClient.getBlogById(location.state.paramId)
      .then((res) => {
        let blogData = res.data;
       // ***   alert("data returned fetch is:" + res.data);
        
        
        setBlogData({
          id: blogData.id,

          title: blogData.title,
          category: blogData.category,
          body: blogData.body,
         
          createdBy: blogData.createdBy,    

    
 titleImageName:blogData.titleImageName,





   titleVideoName:blogData.titleVideoName,
 pdfAttachName:blogData.pdfAttachName,
    status : blogData.blogStatus,
    statusDate : blogData.blogStatusDate
    

        }); 
 // setWebTitleImageURL(BLOG_MEDIA_BASE_URL + "/blogDBimages/" + blogData.id + "/" + blogData.titleImageName);
 // setWebTitleVideoURL(BLOG_MEDIA_BASE_URL + "/blogDBimages/" + blogData.id + "/" + blogData.titleVideoName);
 
    
    
    
    
      let  jpg1url = "";  
      if (blogData.titleImageName === null )
          {
	       setJpg1urlInd (false ); 

	        jpg1url  = null ;

          } 
           
      if (blogData.titleImageName !== null )
         
          {
	     setJpg1urlInd ( true );    
	     jpg1url = BLOG_MEDIA_BASE_URL + "/blogDBimages/" + blogData.id + "/" + blogData.titleImageName;
	    
                                                                          
         } 
               
          let  mp4url = "";  
      if (blogData.titleVideoName === null )
          {
	       setMp4urlInd (false ); 

	        mp4url  = null ;

          } 
           
      if (blogData.titleVideoName  !== null )
         
          {
	     setMp4urlInd (true );    
	    
	     mp4url = BLOG_MEDIA_BASE_URL + "/blogDBvideo/stream/mp4/" + blogData.id 
                                                                           + "/" 
                                                                       + blogData.titleVideoName;
                                                                          
         } 
                               
     {/*          
                             
    const jpgurl = BLOG_MEDIA_BASE_URL + "/blogDBimages/" + blogData.id + "/" + blogData.titleImageName;
    
    const mp4url = BLOG_MEDIA_BASE_URL + "/blogDBvideo/stream/mp4/" + blogData.id 
                                                                           + "/" 
                                                                           + blogData.titleVideoName;
*/}
          
                                                                                                                                       
        setWebTitleImageURL(jpg1url);
       setWebTitleVideoURL(mp4url);                                                                     
    
     

             let  pdfAttachurl = "";  
                                                                           
          if (blogData.pdfAttachName === null )
          {
	setPdfAttachInd(false); 
	      pdfAttachurl = null;
	
          }                                                                           
                                                                          
                                                                           
          if (blogData.pdfAttachName !== null )
         
          {
	        setPdfAttachInd(true); 
	
	
	         pdfAttachurl = BLOG_MEDIA_BASE_URL + "/blogDBpdf/stream/blog3/" + blogData.id 
                                                                           + "/" 
                                                                           + blogData.pdfAttachName;  
         } 
                                                                 
    setWebPdfAttachURL(pdfAttachurl);  
 
      });

}
  

    loadProperty( );
    
    
  

    

}, [location]);



{/*
  const printProperty = (e) => {
    e.preventDefault();
	alert("print property event ")

 
 navigate('/propertyFinder/example' , 
 {state:{blogDataComponent: propertyDataComponent ,     user: paramUser} });

  }
  
*/}


  const backProperties = (e) => {
    e.preventDefault();
	navigate(-1);
   

  }

  const onCancel = async () => {
    console.log("cancel buttom pressed");
   
	  navigate('/profile');
  }

  const  commentsURL =  BLOG_REACT_BASE_URL + "/comments";
   
   
// TO DO INDICATOR CHECKING FOR TITKEIMAGE & VIDEO


  
    return (


      <div className="parent-container">

      
         
<article>
  <h2>{blogData.id}</h2>
  
</article>
                           
        <Table striped bordered hover responsive="md">
                            <thead>
                                <tr>
                                <p className="text-center"> Blog View </p>
                                     <h2>{blogData.title}</h2>
                                     
                                    
                                       <h3>Category : {blogData.category}</h3>
                                <h3>Created By: {blogData.createdBy} Status : {blogData.status} Publish Date : {blogData.statusDate} </h3>   
                                {/*    <h3>Author : {blogData.createdBy}</h3>  */}

<hr/>  


                                </tr>
                            </thead>
                            <tbody>  
        
         </tbody>
                      
 </Table>
 <Table striped bordered hover responsive="md">
                         
                            <tbody>
      


     
     { mp4urlInd && 
        <tr>
        
                  <td>
                  
                    
        <div style={{ display: 'flex' , justifyContent:'center', alignItems:'center'}}>
        <p> React player </p>
     {/*   width="25vw"
      height="25vh"
      
      */}
        <ReactPlayer className='react-player' 
        url={webTitleVideoURL} 
        controls={true}  
        
             
        />
        </div>
         </td>
         </tr>
         }
         <tr>
                  <td>
       
        <p> pdf attachment url for Download </p>

       
        {webPdfAttachURL} 
        
          { pdfAttachInd && 
  //  Rishabh's Resume
            <a href={webPdfAttachURL} download="Article PDF -" target='_blank'>
               <button type="button" class="btn btn-success btn-lg btn-block">Download Article pdf </button>
            </a>
}
 
         </td>


  </tr>
  
  {/*  url="http://localhost:9090/blogDBvideo/stream/mp4/1/test1.mp4"  */}
  
  { jpg1urlInd && 
        <tr>

          <td>
{/* https://www.w3schools.com/css/tryit.asp?filename=trycss_ex_images_card  */}
              <img
                className="title-responsive"
                src={webTitleImageURL}
             
                alt="Title Image"
                
               
              />
 </td>
        
        
        

  </tr>
  }
        
         </tbody>
                        </Table> 
                        
                        
        <Table striped bordered hover responsive="md">
                            <thead>
                                <tr>
         <span style={{fontSize: '20px'}}>
               <CKEditor
                   editor={ Editor }
                   config={{
                   mediaEmbed: {
                              previewsInData: true
                      },
                      
                              language: {
            textPartLanguage: [
                { title: 'Hindi', languageCode: 'hi' },
                { title: 'Gujarati', languageCode: 'gu' },
                { title: 'Arabic', languageCode: 'ar' },
                { title: 'French', languageCode: 'fr' },
                { title: 'Hebrew', languageCode: 'he' },
                { title: 'Spanish', languageCode: 'es' }
            ]
        },
                      
                          
                     }}
                    
                  
                   data={blogData.body}
                   onReady={  editor => {
                       // You can store the "editor" and use when it is needed.
                        alert( 'read only CKEDITOR Editor is ready to use!', editor );
                       console.log( 'Editor is ready to use!', editor );
                       editor.enableReadOnlyMode( 'my-feature-id' );
                       const toolbarElement = editor.ui.view.toolbar.element;

                       
                         if ( editor.isReadOnly ) {
                              toolbarElement.style.display = 'none';
                         } else {
                               toolbarElement.style.display = 'flex';
                                 }
                       
                   } }

                   onBlur={ ( event, editor ) => {
                       console.log( 'Blur.', editor );
                   } }
                   onFocus={ ( event, editor ) => {
                       console.log( 'Focus.', editor );
                   } }
                   
               />
          
       {/*  
         <ReactQuill
                 value={blogData.body}
                 readOnly={true}
                  theme={"bubble"}
          />
*/}
  </span>
                                </tr>
                            </thead>
                            <tbody>  
        
         </tbody>
                      
 </Table>
      
            <div>
 
          <ButtonGroup>
            <Button

              variant="secondary" size="lg"
              onClick={onCancel}>Cancel</Button>
          </ButtonGroup>
          {/*
         <ButtonGroup>
            <Button

              variant="primary" size="lg"
              onClick={printProperty}>Print</Button>
          </ButtonGroup>
          
          */}
          <ButtonGroup>
            <Button variant="secondary"  size="lg" 
            onClick={backProperties}>Back   </Button>
          </ButtonGroup>

      </div>
        <Comments
        commentsUrl={commentsURL} 
        currentUserId={location.state.paramUser}
         postId={location.state.paramId}	>
        
        
    
        </Comments>
        </div>
      
    );
  }




export default ShowBlog;