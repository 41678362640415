import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";
import AuthVerify from "./common/auth-verify";


import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";

import EventBus from "./common/EventBus";

import NewBlogPost from './postComponents/QuillAddComponent/NewBlogPost';
import UploadMP4 from './uploadMP4/UploadMP4';
import BlogList from './listBlogComponents/BlogList';
import BlogUserList from './listBlogComponents/BlogUserList';
import ShowBlog from './showBlogComponents/ShowBlog';

import EditBlog from './editBlogComponents/EditBlog';


import PrivacyPolicy from "./privacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./termsAndConditions/TermsAndConditions";
import Page403 from './errorPages/Page403';
import Page404 from './errorPages/Page404';

import Success200 from './successPages/Success200';


import Comments from './comments/Comments';

import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from './constants';
const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  
  
  let commentURL = BLOG_REACT_BASE_URL + "/comments";

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };

  return (
    <div>
      <nav className="navbar navbar-expand navbar-dark color-nav">
      {/*
        <nav className="navbar navbar-expand navbar-dark  bg-dark">
        <Link to={"/"} className="navbar-brand">
          bezKoder
        </Link>
       */}
       <Link to={"/"} className="navbar-brandx">
          Engineering News
        </Link>
        <div className="navbar-nav mr-auto">
          <li className="nav-itemx">
            <Link to={"/home"} className="nav-linkx">
              Home
            </Link>
          </li>

          {showModeratorBoard && (
            <li className="nav-itemx">
              <Link to={"/mod"} className="nav-linkx">
                Moderator Board
              </Link>
            </li>
          )}

          {showAdminBoard && (
            <li className="nav-itemx">
              <Link to={"/admin"} className="nav-linkx">
                Admin Board
              </Link>
            </li>
          )}

          {currentUser && (
            <li className="nav-itemx">
              <Link to={"/user"} className="nav-linkx">
                User
              </Link>
            </li>
          )}
        </div>

        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-itemx">
              <Link to={"/profile"} className="nav-linkx">
                {currentUser.username}
              </Link>
            </li>
            <li className="nav-itemx">
              <a href="/login" className="nav-linkx" onClick={logOut}>
                LogOut
              </a>
            </li>
            
            
            {showAdminBoard && (
	         <>
            <li className="nav-itemx">
              <Link to={"/newBlogPost"} className="nav-linkx">
                Post Blog
              </Link>
             </li> 

            <li className="nav-itemx">
               <Link to={"/blogUserList"} className="nav-linkx">
                List and Edit All User Blog
              </Link>                   
 </li> 
 
  <li className="nav-itemx">
              <Link to={"/videoUpload"} className="nav-linkx">
                   Video Upload
              </Link>             
             
            </li>
            </>
          )}
{/*
            <li className="nav-itemx">
              <a href="/newBlogPost" className="nav-linkx" >
                Post Blog
              </a>
            </li>
 */}           
            
            
          
             <li className="nav-itemx">
               <Link to={"/blogList"} className="nav-linkx">
                ListBlog
              </Link>                   
                                  
             
            </li>
                      
           {/*
            
           <li className="nav-itemx">
              <Link to={"/showBlog"} className="nav-linkx">
                           
                show Blog
            </Link>
            </li>
            
            
            <li className="nav-itemx">
              <Link to={"/comments"} className="nav-linkx">
                           
                Comments
            </Link>
            </li>
            */}
          </div>
          
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-itemx">
              <Link to={"/login"} className="nav-linkx">
                Login
              </Link>
            </li>

            <li className="nav-itemx">
              <Link to={"/register"} className="nav-linkx">
                Sign Up
              </Link>
            </li>
          </div>
        )}
      </nav>

        <div className="container-fluid ">
        
    {/*    <div className="container mt-3">  */}
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/profile" element={<Profile/>} />
          <Route path="/user" element={<BoardUser/>} />
          <Route path="/mod" element={<BoardModerator/>} />
          <Route path="/admin" element={<BoardAdmin/>} />
          <Route path='/newBlogPost' element={<NewBlogPost   user={currentUser}/> }/>
            <Route path='/videoUpload' element={<UploadMP4  user={currentUser}/> }/>
          <Route path='/blogList' element={<BlogList  user={currentUser}/>}/>
          <Route path='/blogUserList' element={<BlogUserList  user={currentUser}/>}/>
          <Route path='/showBlog' element={<ShowBlog  user={currentUser}/>}/>
         <Route path='/editBlog' element={<EditBlog  user={currentUser}/>}/>
          <Route path='/comments' element={ <Comments
        commentsUrl={commentURL}
        currentUserId={currentUser}
      /> }/>
          {/*
          <Route path="showBlog" 

             element={
             
               <ShowBlog />
            
            }
            />  
            */}
            
            
            
               
           <Route
            path='/privacyPolicy'
            element={
            <PrivacyPolicy user={currentUser}/>
            }
          />

           <Route
            path='/termsAndConditions'
            element={
            <TermsAndConditions user={currentUser}/>
            }
          />
             <Route path='/page403' element={<Page403  user={currentUser}/>}/> 
             
              <Route path='/page404' element={<Page404  user={currentUser}/>}/>  
                <Route path='/success200' element={<Success200  user={currentUser}/>}/>   
               <Route path='/*' element={<Page404  user={currentUser}/>}/>  
        </Routes>
      </div>
              <AuthVerify logOut={logOut}/>
    </div>
  );
};

export default App;
