

import React, { useState } from 'react';
//import ConfigURL from './config/configURL'


import {  ACCESS_TOKEN } from '../constants';
import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../constants';

import axios from "axios";


export const getComments = async (postId) => {
	
	  let config = { headers: {
                              "Content-Type": "application/json",
                             'Access-Control-Allow-Origin' : '*',
                         'Authorization' : 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
                             
                         }
                      }
                
      const  baseURL = BLOG_SITE_BASE_URL +  "/commentapi/comments";
      
      const  listPostCommentsURL  = "/listPostComments";  /* change here */
      
     
      
      const getCommentsPost = baseURL + listPostCommentsURL + "/"  + postId  ;
    	 alert("before calling Axios myHeaders is " + config);
    	 
    	  alert("before axios call url is  " + getCommentsPost);
    	    console.log("COMMENTCLIENT : getComments : before axios call url is  " + getCommentsPost);
    	   return axios.get(
                     getCommentsPost,
                  
                    
                  { headers: {
                              "Content-Type": "application/json",
                             'Access-Control-Allow-Origin' : '*',
                         'Authorization' : 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
                             
                         }
                  }  
    	     )
    	      .then( function(response) {
    	    	  console.log("Response"+ response);
    	    	   alert("Response"+ JSON.stringify(response));
    	    	   alert("Response DATA JSON "+ JSON.stringify(response.data));
    	        
    	          console.log(response.data);
    	          console.log(response.status);
    	          console.log(response.statusText);
    	          console.log(response.headers);
    	          console.log(response.config);
    	          alert("THEN FUNCTION PART GETALL reponse  is :" + JSON.stringify(response))
    	          return(response)
    	        
    })
 
       .catch(function (error) {
	    alert("in the catch error part ")
	  
	  	 
	   alert("useeffect catch [ERROR] stringify  " + JSON.stringify(error))

   alert("before throw new error in restclient" + JSON.stringify(error));
             throw (JSON.stringify(error))
        //     throw new Error(JSON.stringify(error)) 
          // return  error
    });  
 
	
	
	
};

export const createComment = async (postId , text, parentId = null) => {
	
	const  baseURL =   BLOG_SITE_BASE_URL + "/commentapi/comments";
      const  createCommentURL  = "/createComment";  /* change here */
 
	  const formData = new FormData();

formData.append('text', text ); 
formData.append('postId', postId); 
formData.append('parentId', parentId); 

  alert("formData append1")
    

       alert("call back3")

       
      for (var pair of formData.entries())
       {
          console.log(pair[0]+ ', '+ pair[1]); 
          
          alert("for entry is : " + pair[0]   + ', '+ pair[1]);
        }

       

        
        // get JSON from FormData 
         var object = {};
              formData.forEach(function(value, key){
                        object[key] = value;
         });



         const jsonData = JSON.stringify(object);
        const url =   baseURL + createCommentURL ;
         alert("FINALLY BEFORE POST CALL url is :"  + url);
            alert("FINALLY FORM JSON DATA  is :"  + jsonData);
            
          console.log("FINALLY BEFORE POST CALL url is :"  + url);
          console.log("FINALLY FORM JSON DATA  is :"  + jsonData);
         return     axios.post(
                     url,
                    jsonData,
                    
                  { headers: {
                              "Content-Type": "application/json",
                             'Access-Control-Allow-Origin' : '*',
                         'Authorization' : 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
                             
                         }
                  }  
                )

        .then(function(result) {
      alert("after post create comment  success ");
     alert("blog post  Data ADDED : " +  result.data.message + " ADDED id is : " + result.data.id);
     console.log('create comment  in result ' + result);
     console.log(result);
    console.log(`upload form in rest client Status code: ${result.status}`);
    console.log(`upload form in rest client  return errorStatus text: ${result.statusText}`);
    console.log(`upload form in rest client Request method: ${result.request.method}`);
    console.log(`upload form in rest client Path: ${result.request.path}`);

 //   console.log(`upload form in rest client Date: ${result.headers.date}`);
    console.log(`upload form in rest client Data: ${result.data}`);  
    console.log(`uploaded form in rest client data updated id is : ${result.data.id}`);  
   
  console.log(`blog post Data Uploaded  :  ${result.data.message}  updated id is : ${result.data.id}`);
    
      alert("alert business addresss Data Uploaded JSON STRINGIFY RESULT.DATA : " +  JSON.stringify(result.data));
     alert("alert business addresss Data Uploaded  : " +  result.data.message + " updated id is : " + result.data.id);
     console.log("returning from then part add business address ");
     alert("last stmt : returning from then part  add business address");
    return(result);
      //alert.show("upload success");
   })
   
   
       .catch(function (error) {
     console.log("catch part error ");
     alert("catch part error");

    if (error.response) {
      // Request made and server responded
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

       return error
    });    
       

	

};

export const updateComment = async (text) => {
  return { text };
};

export const deleteComment = async () => {
  return {};
};




