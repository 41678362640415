  import React, { useState } from 'react';



import {  ACCESS_TOKEN } from '../constants';
import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../constants';

import axios from "axios";


/* 
------------------------------------------------------------------------------------------

ref article : https://bobbyhadz.com/blog/react-export-multiple-functions

-------------------------------------------------------------------------------------
*/
//const c = new ConfigURL();


//const myHeaders = new Headers();

//myHeaders.append('Content-Type', 'application/json');

//if(localStorage.getItem(ACCESS_TOKEN)) {
//	console.log("access token is :"  + localStorage.getItem(ACCESS_TOKEN));
//	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN));
//}

  
  


export default {
	
	
	 editSubmit2: (formData ) => {
        console.log("in the UPLOAD FUNCTION 1") 
        
        alert("in the editsubmit2 FUNCTION 1") 
        
       
      /*
        const uploadConfig = {
                headers: {
                    'content-type': 'multipart/form-data'  ,
                   	'Authorization' : 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
                    	
                    	
                }
            };
            
        */    
        console.log("in the UPLOAD FUNCTION 2")  
        alert("formdata upload function 2")  
       
        
        console.log("Upload form data is below 3");
        console.log(...formData);
        alert(...formData);
        console.log("jwx is :" +   localStorage.getItem(ACCESS_TOKEN));
        alert("before jwx is :" +   localStorage.getItem(ACCESS_TOKEN));
        
        
        /*
        const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization' : 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
                }
            };

        */
    

        console.log("before AXIOS Call" );
         alert("formdata is :" + {...formData});	
        alert("formdata upload axios post multipart  AXIOS POST CALL TO SPRING BOOT")   
        
       
        
        
      const  baseURL = BLOG_SITE_BASE_URL + "/postapi";
      const  editPathURL  = "/editBlogSubmit2";
      
      const url =   baseURL + editPathURL ;
      console.log("before calling axios to url :" + url);
      alert("before calling axios to url :" + url);
        return axios.post(
                    url,   
                    formData,
                    {
                        headers: {
                            "Authorization": 'Bearer ' + localStorage.getItem(ACCESS_TOKEN) ,
                            "Content-type": "multipart/form-data",
                              'Access-Control-Allow-Origin' : '*',
                        },                    
                    }
                )
          
          
        .then(function(result) {
      alert("after axios post result ");
     console.log('edit submitted form in rest client Logging result ' + result);
     console.log(result);
    console.log(`upload form in rest client Status code: ${result.status}`);
    console.log(`upload form in rest client Status text: ${result.statusText}`);
    console.log(`upload form in rest client Request method: ${result.request.method}`);
    console.log(`upload form in rest client Path: ${result.request.path}`);

    console.log(`upload form in rest client Date: ${result.headers.date}`);
    console.log("upload form in rest client Data: " + JSON.stringify(result.data));  
 
   
    console.log("Property Data Uploaded  :"  + "updated id is :" + result.data.id);
   alert("Property Data Uploaded  : " +  result.data.message + " updated id is : " + result.data.id);
    return(result);
      //alert.show("upload success");
   })
    .catch(function(error) {
      console.log('upload form in rest client  What happened? ' + error);
      console.log(JSON.stringify(error))
       alert("Property Add Error");
       alert(JSON.stringify(error));
       return(error);
    });        
              
        
     
  
    },
    

	rewritePost :( formData ) => {

        
     //   const url = c.serverUrl + '/api/v2/upload';
     

      const  baseURL = BLOG_SITE_BASE_URL + "/postapi";
      const  editPathURL  = "/updatePost/";
    /*  
              const config = {
	
	  headers: {
    "Content-Type": "application/json"
  

  }

            };
*/
        console.log("in the UPLOAD FUNCTION 2")  
        alert("EDITBLOGCLIENT :  formdata upload function 2")  
       
        
        console.log("Upload form data is below 3");

        
      
       // console.log("jwx is :" +   localStorage.getItem(ACCESS_TOKEN));
      //  alert("before jwx is :" +   localStorage.getItem(ACCESS_TOKEN));


    

        console.log("before AXIOS Call");	
         alert("formdata upload axios post market act plaan  AXIOS POST CALL TO SPRING BOOT")  
         alert ("url to add BLOG :" +  baseURL + editPathURL );
        for (var pair of formData.entries())
       {
          console.log(pair[0]+ ', '+ pair[1]); 
          
          alert("editBlogClientfor formData entry is : " + pair[0]   + ', '+ pair[1]);
        }
        
        // get JSON from FormData 
         var object = {};
              formData.forEach(function(value, key){
                        object[key] = value;
         });



         const jsonData = JSON.stringify(object);
        const url =   baseURL + editPathURL  + object.id;
         alert("editBlogClient FINALLY BEFORE POST CALL url is :"  + url);
            alert("FINALLY FORM JSON DATA  is :"  + jsonData);
         return     axios.put(
                     url,
                    jsonData,
                    
                  { headers: {
                              "Content-Type": "application/json",
                             'Access-Control-Allow-Origin' : '*',
                               'Authorization' : 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
                             
                         }
                  }  
                )

        .then(function(result) {
      alert("after axios post result xref table then part data written success ");
     alert("blog post  Data ADDED : " +  result.data.message + " ADDED id is : " + result.data.id);
     console.log("upload form in rest client Logging result " + result);
     console.log(result);
    console.log("upload form in rest client Status code: " + result.status);
    console.log("upload form in rest client  return errorStatus text: " + result.statusText);
    console.log("upload form in rest client Request method:" + result.request.method);
    console.log("upload form in rest client Path: " + result.request.path);

 //   console.log("upload form in rest client Date:" + result.headers.date);
    console.log("upload form in rest client Data: " + result.data);  
    console.log("uploaded form in rest client data updated id is : " + result.data.id);  
   
  console.log("blog post Data Uploaded  : " + result.data.message + "updated id is : " +result.data.id );
    
    
     alert("alert business addresss Data Uploaded  : " +  result.data.message + " updated id is : " + result.data.id);
     console.log("returning from then part add business address ");
     alert("last stmt : returning from then part  add business address");
    return(result);
      //alert.show("upload success");
   })
   
   
       .catch(function (error) {
     console.log("catch part error ");
     alert("catch part error");

    if (error.response) {
      // Request made and server responded
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

       return error
    });    
       

    },

  }