import React, { Component } from 'react';
// import dynamic from 'next/dynamic';
import ReactQuill from 'react-quill';
import {  ACCESS_TOKEN } from '../constants';
import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../constants';
         
import axios from "axios";
//import Modal from '@material-ui/core/Modal';
// import Button from '@material-ui/core/Button';

//ref : https://kaloraat.com/articles/react-quill-wysiwyg-rich-text-editor-image-upload-to-server

export default {
         callmp4post : (formData ) => {
        console.log("in the UPLOAD FUNCTION 1") 
        
        
        alert("in the call mp4 post  FUNCTION 1") 
      const url =  BLOG_SITE_BASE_URL + '/api/videoupload';
   // 'Authorization' : 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
      const config = {
        headers: {
	     
             'content-type': 'multipart/form-data'
           
             
        }
    }
       
       
        console.log("in the UPLOAD FUNCTION 2")  
        alert("formdata upload function 2")  
       
        
        console.log("Upload form data is below 3");
     //   console.log(...formData);
       // alert(...formData);
        console.log("jwx is :" +   localStorage.getItem(ACCESS_TOKEN));
        alert("before jwx is :" +   localStorage.getItem(ACCESS_TOKEN));
        /*
        const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization' : 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
                    
                    
                }
            };

        */
    

        console.log("before AXIOS Call");	
         alert("AXIOS.POST RETURN AXIOS.POST CALL  final post : formdata upload axios post multipart  AXIOS POST CALL TO SPRING BOOT")  
         alert("form data "  )
        // Display the key/value pairs
       for (var pair of formData.entries())
       {
          console.log(pair[0]+ ', '+ pair[1]); 
          
          alert("for entry is : " + pair[0]+ ', '+ pair[1]);
        }
        
        var object = {};
formData.forEach((value, key) => object[key] = value);
var json = JSON.stringify(object);
console.log(json);
alert(json);
alert("uploadconfig is : " + config);
alert("uploadconfig is stringify  : " + JSON.stringify(config));
alert("url :" + url);
alert ("final call to  callmp4post")
       return axios.post(
                    url,   
                    formData,
                    config,
                )
          
          
        .then(function(result) {
      alert("after axios post result ");
     console.log('upload form in rest client Logging result ' + result);
     console.log(result);
    console.log(`upload form in rest client Status code: ${result.status}`);
    console.log(`upload form in rest client Status text: ${result.statusText}`);
    console.log(`upload form in rest client Request method: ${result.request.method}`);
    console.log(`upload form in rest client Path: ${result.request.path}`);

    console.log(`upload form in rest client Date: ${result.headers.date}`);
    console.log(`upload form in rest client Data: ${result.data}`);  
    console.log(`uploaded form in rest client data updated id is : ${result.data.id}`);  
   
    console.log("Property Data Uploaded  :" + result.data.message + "updated id is :" + result.data.id);
    
   alert("Property Data Uploaded  : " +  result.data.message + " updated id is : " + result.data.id);
   alert("returning result from restClient.jsx");
    return(result);
      //alert.show("upload success");
   })
    .catch(function(error) {
      console.log('upload form in rest client  What happened? ' + error);
      console.log(JSON.stringify(error))
       alert("Property Add Error");
       alert(JSON.stringify(error));
       return(error);
    });        
              
        
     
  
    },
     





}