import { Link } from "react-router-dom";
import img from './errorImage.jpg';
import './Page403.css';

//  <div style={{ backgroundImage: "url(/errorImage403.jpg)" } }> 
// {/*	<div className="background-red">   */}
export default function Page404() {
  return (
	
	<div style={{ backgroundImage: "url(/errorImage403.jpg)" } }>


       <div  className="centered">
       
       
       <h1>
    <p>Oops!  Requested Page not Found Error Status 404 .</p>
    
    <br></br>
    
    <p>Here are some helpful links:</p>
    <br></br>
    <p><Link to='/'>Home</Link></p>
    
    
   </h1>
</div>
  
    

    </div>
  )
}