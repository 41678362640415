
import React, { useState, useEffect } from 'react';
// import React, { Component } from 'react';
import axios from 'axios';
// import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import DOMPurify from "dompurify";
// import ScrollArea from "react-scrollbar";
import { Scrollbars } from 'react-custom-scrollbars-2';
//import "./styles.scss";

import parse from "html-react-parser";
// import renderHTML from 'react-render-html';


import { useNavigate } from 'react-router-dom';
import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../constants';

// import PageImage from '../common/PageImage/PageImage1';
// import PageTop from '../common/PageTop/PageTop';
// import PageBottom from '../common/PageBottom/PageBottom';


import  './TermsAndConditions.css';

const TermsAndConditions = () => {
	
const navigate = useNavigate();


           
const[htmlContent , setHtmlContent] = useState( "");

     const mystyle = {
 
      fontFamily: "Arial",
      fontSize: "16px"
    };
    
 const  handleBack = (e) => {
	
	     alert("back button pressed")  
      navigate(-1);
    
  }



  
  

 /*   
    
getPolicy = () => {
	  const policyURL = BLOG_MEDIA_BASE_URL + `/bloghtml/stream/htmlpage/policy.html`;
	  alert("before axios call POLICYURL is: " + policyURL);
	 
	
	 axios.get(policyURL, 
	 
	  {
                        headers: {
                           
                             'Access-Control-Allow-Origin' : '*',
                        },                    
                    }
	 
	)
      .then(response => {
        alert("after axios call Response");
        console.log("Response" + response);
       
        console.log("response content display:" + response.data.htmlContent);

        console.log("Response Data is :" + JSON.stringify(response.data));
        console.log(response.status);
        console.log(response.statusText);
        console.log(response.headers);
      
	 const htmlContent  = response.data.htmlContent;
	       
	 
	 
	 
        this.setState({ htmlContent : JSON.stringify(response.data) })
        const cleanHTML = DOMPurify.sanitize(response.data, {
         USE_PROFILES: { html: true },
         });
          setHtmlContent(cleanHTML )
	      alert("cleanHTML html is " + htmlContent);

})

 .catch(function (error) {
    if (error.response) {
	   alert("error response :" + JSON.stringify(error.response))
      // Request made and server responded
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
	 alert("error request :" + JSON.stringify(error.request))
      // The request was made but no response was received
      console.log(error.request);
    } else {
	 alert("error message :" + JSON.stringify(error.message))
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

})
alert("end of getpolicy html is " + htmlContent);
}

*/
  useEffect(( ) => { 
	alert('before componentdid mount privacy policy');
	
	const getTermsAndConditions = ( ) => {
		
    const TermsAndConditionsURL  = BLOG_MEDIA_BASE_URL + `/bloghtml/stream/htmlpage/TermsAndConditions.html`;
    
	  alert("before axios call TermsAndConditionsURL  is: " + TermsAndConditionsURL );
	 
	
	 axios.get(TermsAndConditionsURL , 
	 
	  {
                        headers: {
                           
                             'Access-Control-Allow-Origin' : '*',
                        },                    
                    }
	 
	)
      .then(response => {
        alert("after axios call Response");
        console.log("Response" + response);
       
        console.log("response content display:" + response.data);

        console.log("Response Data is :" + JSON.stringify(response.data));
        console.log(response.status);
        console.log(response.statusText);
        console.log(response.headers);
      
	 const htmlContent  = response.data;
	       
	 
	 alert("htmlContent " + htmlContent);
	 
  //      setHtmlContent(JSON.stringify(response.data) )
    //    const cleanHTML = DOMPurify.sanitize(response.data, {
     //    USE_PROFILES: { html: true },
    //     });
          setHtmlContent( htmlContent )
	      alert("cleanHTML html is " + htmlContent);

})

 .catch(function (error) {
    if (error.response) {
	   alert("error response :" + JSON.stringify(error.response))
      // Request made and server responded
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
	 alert("error request :" + JSON.stringify(error.request))
      // The request was made but no response was received
      console.log(error.request);
    } else {
	 alert("error message :" + JSON.stringify(error.message))
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

})
alert("end of getpolicy html is " + htmlContent);
    }
    
    
    

  getTermsAndConditions();

   
    
    
  

    

}, []);
  
    
    
    
    
    
     return (
         <div Container fluid="md">


    
     {/*    
      <PageImage></PageImage>
      <PageTop></PageTop>
     */}
               
           <div className="a"  style={mystyle}>
            <Scrollbars style={{ width: 2500, height: 2500 }}>
      
    
     <div>{parse(htmlContent)}</div>

     
     </Scrollbars>
   
   </div>

  
           


     
            <button className="btn btn-success" color="primary" onClick={(event) => handleBack(event)} >Back </button>
  
</div>




    );


  }


export default TermsAndConditions
