import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import homeImage from './HomeImage1.jpg';

import './Home.css';
const Home = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  return (
   <div className="content"> 
      <header  style={{textAlign: "center", fontsize:"20px"}} >
 {/* <div className="container-fluid"> 
      <header className="jumbotron-fluid">
      
      
         <img src={homeImage}  alt="home imaage. logo" style={{width:"1928px", height:"1176px ",  objectfit: "fill"}}   /> 
      */}
      <h3  className="centerText">{content}</h3>  
      </header>
    </div>
  );
};

export default Home;
