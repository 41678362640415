import React from 'react';
import axios from 'axios';
import Pagination from "react-js-pagination";
import BlogFeed from './BlogFeed';
import { ACCESS_TOKEN } from '../../constants';
// import Carousel from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import './FetchBlog.css';
import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../../constants';
class FetchBlog extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      articlesDetails: [],
      activePage: 0,
      totalPages: 0,
      itemsCountPerPage: null,
      totalItemsCount: 0

    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchURL = this.fetchURL.bind(this);

   

    console.log("this is constructor fetchBLOG");
    console.log("fetchNewsuser name GIVEN TO NEWSFEED" + JSON.stringify(props));
  }



  fetchURL(page) {

    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
         'Access-Control-Allow-Origin' : '*',
      }

    }
    // const myHeaders = new Headers();

    //  myHeaders.append('Content-Type', 'application/json');
    //  console.log("before if statement for fetch news header :"  + localStorage.getItem(ACCESS_TOKEN));
    //  if(localStorage.getItem(ACCESS_TOKEN)) {
    //  	console.log("from fetch news config function access token is :"  + localStorage.getItem(ACCESS_TOKEN));
    //  	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN));

    // }
    console.log("before calling Axios myHeaders is " + JSON.stringify(config));
     console.log("FETCHNEWS  axios call with page no :" + page );
    // *** alert("FETCHNEWS  axios call with page no :" + page );
    axios.get(BLOG_SITE_BASE_URL + `/postapi/getAllBlog?page=${page}&size=2`, config)
      .then(response => {

       // ***  alert("Response" + response);
       // ***   alert("total elements:" + response.data.totalElements);
       // ***   alert("response content display:" + response.data.content);
       // ***   alert("response STRINGIFY content display:" + JSON.stringify(response.data.content));
       // ***   alert("Response Data is :" + JSON.stringify(response.data));
       // ***   alert(response.status);
       // ***   alert(response.statusText);
       // ***   alert(response.headers);
       // ***   alert(response.config);
        
        
        console.log("Response" + response);
        console.log("total elements:" + response.data.totalElements);
        console.log("response content display:" + response.data.content);

        console.log("Response Data is :" + JSON.stringify(response.data));
        console.log(response.status);
        console.log(response.statusText);
        console.log(response.headers);
        console.log(response.config);
        const totalPages = response.data.totalPages;
        const itemsCountPerPage = response.data.size;
        const totalItemsCount = response.data.totalElements;

        this.setState({ totalPages: totalPages })
        this.setState({ totalItemsCount: totalItemsCount })
        this.setState({ itemsCountPerPage: itemsCountPerPage })

        console.log("Response Data JSON is :" + JSON.stringify(response.data.content));
        console.log("response display:" + response.data.content);
        const results = response.data.content;



/*
        console.log("Response" + response);
        console.log("total elements:" + response.data.totalElements);
        console.log("response content display:" + response.data.content);

        console.log("Response Data is :" + JSON.stringify(response.data));
        console.log(response.status);
        console.log(response.statusText);
        console.log(response.headers);
        console.log(response.config);
      //  const totalPages = response.data.totalPages;
      //  const itemsCountPerPage = response.data.size;
      //  const totalItemsCount = response.data.totalElements;

     //   this.setState({ totalPages: totalPages })
    //    this.setState({ totalItemsCount: totalItemsCount })
    //    this.setState({ itemsCountPerPage: itemsCountPerPage })

        //console.log("Response Data JSON is :" + JSON.stringify(response.data.content));
      //  console.log("response display:" + response.data.content);
      //  const results = response.data.content;
        
      //   const results = response.data;
     //   console.log("result array :" + JSON.stringify(results));
       // const updatedResults = results.map(resultRow => {

         // var timestamp = new Date(results.propAvailDate)
        //  var dateString = timestamp.toGMTString()

        //  return {
        //    ...results, dateString
        //  }
        
        
        
        // return {
        //   ...results
        // }
       // });
       // console.log("updated results:" + JSON.stringify(updatedResults));
      //  this.setState({ articlesDetails: updatedResults });
    */ 
       this.setState({ articlesDetails: results});
      }
      );
  }
/*
  componentDidMount() {
    console.log(" componentDidMount fetchurl active page no is" + this.state.activePage);
    this.state.activePage = 0; 
   alert(`componentDidMount firs before fetchurl  mount page number active page is ${this.state.activePage}`);
    this.fetchURL(this.state.activePage)
    this.state.activePage = 1; 
     alert(`after first fetch url component mount page number active page is ${this.state.activePage}`);
  }
*/

  componentDidMount () {
	 // ***   alert("fetch blog -- component did mount ");
      this.fetchURL(this.state.activePage)
    }

  handlePageChange(page) {
    console.log(`active page is ${page}`);
    page--;
    this.fetchURL(page)
    page++
    this.setState({activePage: page})
    
   

    }
/*
    handlePageChange(pageNumber) {
	alert(`before handle page number ACTIVE PAGE  is ${this.state.activePage}`);
    console.log(`before handle page number active page is ${pageNumber}`);
    alert(`CHECK ACTIVE PAGE & FETCH PAGE NUMBER : before handle page number  is ${pageNumber}`);
    pageNumber--;
   // this.setState({ activePage: pageNumber })
    console.log("fetching data from  page no on subtraction :" + pageNumber)
    this.fetchURL(pageNumber)
    pageNumber++;
    console.log(`after handle page number active page is ${pageNumber}`);
    alert(`after handle page number active page is ${pageNumber}`);
  }
  
*/
  


  populateRowsWithData = () => {

    // const baseUrl = "D:/project-rent-march052020/spring-security-react-ant-design-polls-app-master/polling-app-client/src/rentPropSrc/components/assets/images/"
    //const baseUrl = "./assets/images/";
    //  const baseUrl = "http://localhost:80/rentimages/"
    
    
 //   const baseUrl = "http://localhost:9090/rentDBimages/"
    const articleData = this.state.articlesDetails.map(article => {
 // ***  alert("FETCHBLOG loop here : user name list show BEFORE PASSED TO NEWSFEED :" + this.props.user)
      console.log("user name list show BEFORE PASSED TO NEWSFEED :" + this.props.user)
      
      var titleImageURL = BLOG_MEDIA_BASE_URL + "/blogDBimages/" + article.id + "/" + article.titleImageName;
      
    // ***    alert("titleImageURL is :" + titleImageURL);
      
 
      return (
  

        <BlogFeed
          keyid={article.id}
          title={article.title}

     titleImageName={article.titleImageName}
    
   titleImageType={article.titleImageType}
    titleImageSize={article.titleImageSize}
    titleImageURL={titleImageURL}
    

     titleVideoName={article.titleVideoName}
    
   titleVideoType={article.titleVideoType}
    titleVideoSize={article.titleVideoSize}
    createdBy={article.createdBy}
          body={article.body}
          user = {this.props.user}
          blogStatus={article.blogStatus}
          blogStatusDate={article.blogStatusDate}
        />
  
        );
    })
    

    return articleData
  }
      

  render() {
   
    return (

	    <div> 
	    
{/* <div style={{ display: 'block',
                   width: 1500, padding: 5 }}>
	  */}    
	     
	       <Container fluid   style={{
          backgroundColor: '#B9B49E'
          
        }}>
	          <div>  

     {/*    <div className="d-flex justify-content-end">  */}

        <Pagination
         hideNavigation
         activePage={this.state.activePage}
         itemsCountPerPage={this.state.itemsCountPerPage}
         totalItemsCount={this.state.totalItemsCount}
         pageRangeDisplayed={25}
         onChange={this.handlePageChange}
         itemClass='page-item'
         linkClass='btn btn-light'
          prevPageText="Prev"
          nextPageText="Next"
          firstPageText="First"
          lastPageText="Last"
         />
      </div>
     {/*   <div className="d-flex flex-wrap"> */} 
        <div>  

        {this.populateRowsWithData()}
     </div>
     <p> active page is :{this.state.activePage} </p>
     
    <div className="d-flex justify-content-end">  
        <Pagination
         hideNavigation
         activePage={this.state.activePage}
         itemsCountPerPage={this.state.itemsCountPerPage}
         totalItemsCount={this.state.totalItemsCount}
         pageRangeDisplayed={25}
         onChange={this.handlePageChange}
         itemClass='page-item'
         linkClass='btn btn-light'
          prevPageText="Prev"
          nextPageText="Next"
          firstPageText="First"
          lastPageText="Last"
         />
      </div>

            </Container>

   </div>
     
    );
  }

}

export default FetchBlog;
