

import React , {useState}  from 'react';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import '../../../node_modules/react-quill/dist/quill.bubble.css';
import { Container, Button, ButtonGroup, Card, Col, File } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import { useNavigate } from 'react-router-dom';

import "./BlogFeedUser.css" 


const BlogFeedUser  = (props) => {

const [id , setId ] = useState("");
const [postBody , setPostBody ] = useState("");
const navigate = useNavigate();

/*
  const editProperty = (id) =>  {


alert("props in NEWSFEED IS :" + JSON.stringify(props));
    var varId = id;
    console.log("newsfeed PUSH param id is :" + varId);
  

    navigate('/blogFinder/showBlog',{state:{paramId: id , paramUser: props.user} });
  } 
  */
  
    const showBlog = ()  => {
   // ***  alert("IN THE SHOWBLOG FUNCTION OF BLOGFEED")
     
   // ***   alert("navigate to /showBlog with"   +  props.keyid  +  "user id :" + JSON.stringify(props.user))
   var varId = props.keyid;
   console.log("newsfeed PUSH param id is :" + varId);
   console.log("newsfeed PUSH param2 user  is :" + JSON.stringify(props.user));
   console.log("newsfeed PUSH param2 username  is :" + props.user.username);
  // *** alert("navigate to /showBlog with"   +  varId  +  "user id :" + JSON.stringify(props.user))
   navigate('/showBlog',{state:{paramId: varId , paramUser: props.user} });  
   

  } 
  

  
  
    const editBlog = ()  => {
   // ***  alert("IN THE SHOWEDIT BLOG FUNCTION OF BLOGFEED USER")
     
   // ***   alert("navigate to /editBlog with"   +  props.keyid  +  "user id :" + JSON.stringify(props.user))
   var varId = props.keyid;
   console.log("BLOGFEEDUSER PUSH param id is :" + varId);
   console.log("BLOGFEEDUSER PUSH param2 user  is :" + JSON.stringify(props.user));
   console.log("BLOGFEEDUSER PUSH param2 username  is :" + props.user.username);
   // ***  alert("navigate to /editBlog with"   +  varId  +  "user id :" + JSON.stringify(props.user))
   navigate('/editBlog',{state:{paramId: varId , paramUser: props.user} });  
   

  } 

    return (

	
		
		 	<div style={{ background:'#8ae234' }}>  
		

		<article>
		         
           <h2>{props.id}</h2>
          <table>
          <tr>
          <td>
		<a onClick={showBlog} ><img onClick={showBlog}  className="img-list" src={props.titleImageURL} alt="image"  />
		</a>
		</td>
		<td>
		<h4><a onClick={showBlog} >{props.title}</a></h4> 
		
		</td>
         </tr>
          </table>
<h5>Created By: {props.createdBy} {props.blogStatus} Publish Date : {props.blogStatusDate} </h5> 
		
    {/*
         <ReactQuill
                 value={props.body}
                 readOnly={true}
                  theme={"bubble"}
          />

  
         
           <h2>{props.id}</h2>
           
             */}
           <br/>
           {/*
									<Button variant="primary" size="sm"
										onClick={() => showBlog(props.keyid)}
										
									>
										Show Blog
								</Button>

                                        <br/>
                                       
   
				*/}		
				
									</article>	
				
					
		
										<Button variant="primary" size="sm"
										onClick={() => showBlog(props.keyid)}
										
									>
										Show Blog
								</Button>
					<Button variant="primary" size="sm"
										onClick={() => editBlog()}
										
					>
										Edit Blog
					</Button>
					<hr style={{height:"2px",borderWidth:0,color:"gray",backgroundColor:"lightblue"}}/>
				</div>
			

    );
  }

export default BlogFeedUser;

