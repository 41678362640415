import React, { useState } from 'react';
//import ConfigURL from './config/configURL'
// import { useNavigate } from "react-router-dom";

import {  ACCESS_TOKEN } from '../constants';

import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../constants';
import axios from "axios";


/* 
------------------------------------------------------------------------------------------

ref article : https://bobbyhadz.com/blog/react-export-multiple-functions

-------------------------------------------------------------------------------------
*/
//const c = new ConfigURL();


//const myHeaders = new Headers();

//myHeaders.append('Content-Type', 'application/json');

//if(localStorage.getItem(ACCESS_TOKEN)) {
//	console.log("access token is :"  + localStorage.getItem(ACCESS_TOKEN));
//	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN));
//}

  
  
	



	 const BlogPostClient = (formData ) => {
	
    //  const navigate = useNavigate();


        console.log("in the UPLOAD FUNCTION DATA IS 1" + JSON.stringify(formData) ) 
        
        // *** alert("in the BlogPostClient -ADD DATA writePost : (formData ) FUNCTION 1") 
        
     //   const url = c.serverUrl + '/api/v2/upload';
     

      const  baseURL = BLOG_SITE_BASE_URL + "/postapi";
      const  addPathURL  = "/postblog";
    /*  
              const config = {
	
	  headers: {
    "Content-Type": "application/json"
  

  }

            };
*/
        console.log("in the UPLOAD FUNCTION 2")  
      // ***  alert("formdata upload function 2")  
       
        
        console.log("Upload form data is below 3");

        
       // ***  alert('formdata before Axios call strigify  ' + JSON.stringify(formData));
       // console.log("jwx is :" +   localStorage.getItem(ACCESS_TOKEN));
      //  alert("before jwx is :" +   localStorage.getItem(ACCESS_TOKEN));


    

        console.log("before AXIOS Call");	
         // *** alert("formdata upload axios post market act plaan  AXIOS POST CALL TO SPRING BOOT")  
         // *** alert ("url to add BLOG :" +  baseURL + addPathURL );
        for (var pair of formData.entries())
       {
          console.log(pair[0]+ ', '+ pair[1]); 
          
          // *** alert("for entry is : " + pair[0]   + ', '+ pair[1]);
        }
        
        // get JSON from FormData 
         var object = {};
              formData.forEach(function(value, key){
                        object[key] = value;
         });



         const jsonData = JSON.stringify(object);
        const url =   baseURL + addPathURL ;
// *** alert("FINALLY BEFORE POST CALL url is :"  + url);
 // ***            alert("FINALLY FORM JSON DATA  is :"  + jsonData);
            
            
            
           return  axios.post(
                    url,   
                    formData,
                    {
                        headers: {
                            "Authorization": 'Bearer ' + localStorage.getItem(ACCESS_TOKEN) ,
                            "Content-type": "multipart/form-data",
                             'Access-Control-Allow-Origin' : '*',
                        },                    
                    }
                )


        .then(function(result) {
      // *** alert("after axios post result xref table then part data written success ");
     // *** alert("blog post  Data ADDED : " +  result.data.message + " ADDED id is : " + result.data.id);
     console.log('upload form in rest client Logging result ' + result);
     console.log(result);
    console.log(`upload form in rest client Status code: ${result.status}`);
    console.log(`upload form in rest client  return errorStatus text: ${result.statusText}`);
    console.log(`upload form in rest client Request method: ${result.request.method}`);
    console.log(`upload form in rest client Path: ${result.request.path}`);

 //   console.log(`upload form in rest client Date: ${result.headers.date}`);
    console.log(`upload form in rest client Data: ${result.data}`);  
    console.log(`uploaded form in rest client data updated id is : ${result.data.id}`);  
   
  console.log(`blog post Data Uploaded  :  ${result.data.message}  updated id is : ${result.data.id}`);
    
    
   // ***  alert("alert business addresss Data Uploaded  : " +  result.data.message + " updated id is : " + result.data.id);
     console.log("returning from then part add business address ");
    // *** alert("last stmt : returning from then part  add business address");
    return(result);
      //alert.show("upload success");
   })
   
   
       .catch(function (error) {
     console.log("catch part error " + error );
     alert("Blog Client  catch part error" + error);
 alert("Blog Client  catch part error status " + JSON.stringify(error));
 alert("Blog Client  catch part error status is :" + error.status);
         switch (error.status) {
            case 403:
                 alert("handle navigate 403");
                 console.log("handle navigate 403");
              //   navigate("/page403");
                 break;
                //handle route to the page
            case 404: 
            alert("handle navigate 404");
               // navigate("/page404");
                break;
           default :
           break;
         }
       return error;
    });    
       

    }
         
export default BlogPostClient;
