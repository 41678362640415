import React, { Component } from 'react';
// import dynamic from 'next/dynamic';
import ReactQuill from 'react-quill';
import {  ACCESS_TOKEN } from '../constants';
import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../constants';
import axios from "axios";
//import Modal from '@material-ui/core/Modal';
// import Button from '@material-ui/core/Button';
import '../../node_modules/react-quill/dist/quill.snow.css';
//ref : https://kaloraat.com/articles/react-quill-wysiwyg-rich-text-editor-image-upload-to-server

export default {
     callpost  : async   (formData) => {
       
   
	alert("calling axios mycomponent client");
	
alert("form data is :" + JSON.stringify(formData));

alert(" before calling axios.post(" + BLOG_SITE_BASE_URL +"/api/upload");


console.log(" before calling axios.post(" + BLOG_SITE_BASE_URL +"/api/upload");

          
 let result =  await axios.post(BLOG_SITE_BASE_URL + '/api/upload',  formData,
       {
                    headers: { 
                     "Content-Type": `multipart/form-data: boundary=add-random-characters`,
 
                         'Access-Control-Allow-Origin' : '*',
                         
                         
                  'Authorization' : 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
                    }, 
                    
                  
         })
        alert("result.data" + result.data)
       return (result.data);
          
        /*  
        .then(function(result) {
      alert("after axios post result " + JSON.stringify(result));
       alert("retrning from MyConponent Client then" + result.data);
   
      //alert.show("upload success");
   })
    .catch(function(error) {
      console.log('upload form in rest client  What happened? ' + error);
      console.log(JSON.stringify(error))
       alert.show("Property Add Error");
     alert("retrning from MyConponent Client catch ");
     
    });        
              
        
      return(result.data);
  */
    },




}