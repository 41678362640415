import React, { useState  , useRef  , useEffect } from 'react';
// this set component is working for edit -- important keep 
import { useNavigate,  useLocation } from "react-router-dom";
import showBlogClient from '../showBlogComponents/showBlogClient';
//import { EditorState } from 'draft-js';


//import axios from "axios";
//import { convertToHTML } from 'draft-convert';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//import { EditorState, convertToRaw, ContentState } from 'draft-js';
//import { Editor } from 'react-draft-wysiwyg';

// import draftToHtml from 'draftjs-to-html';

//import htmlToDraft from 'html-to-draftjs';


import DOMPurify from 'dompurify';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './EditBlog.css';

import { Form , Button } from 'react-bootstrap';

import editBlogClient from './editBlogClient';

//import Editor from './Editor';
 import MyEditCkeditorComponent from './MyEditCkeditorComponent';
//import  './MyComponent.css';

// ref : https://blog.logrocket.com/building-rich-text-editors-in-react-using-draft-js-and-react-draft-wysiwyg/


const EditBlog = () => {
	
	
const [blogId , setBlogId]  = useState( null );
const [ textTitle , setTextTitle] = useState( '');
const [ textCategory , setTextCategory] = useState( '');
const [ imageDetails , setImageDetails] = useState( '');
const [message , setMessage] =  useState( '');
const [block , setBlock] = useState(false)	;
const [modal , setModal] = 	useState(false)	;			
const [thumbnail, setThumbnail] = useState();
const [test , setTest] = useState("testprops");
// const [files, setFiles] = useState([]);
   alert("in the showBlog  to get asis db data");
  const componentRef = useRef();

   
    
  	const navigate = useNavigate();
  	
  	const location = useLocation();

  	let [paramId , setParamId] = useState();
    let [paramUser , setParamUser] = useState()

  
    let [webTitleImageURL , setWebTitleImageURL]  = useState();
  	
   let [webTitleVideoURL , setWebTitleVideoURL]  = useState();
  	
   let [webPdfAttachURL , setWebPdfAttach]  = useState();

	let [blogData2, setBlogData2] = useState(
		{
  
      id: '',
      title: '',
      category: '',
      body: '',
       createdBy: '',  
       
       

    
 titleImageName:'',





   titleVideoName:'',
   pdfAttachName:'',
    status :'',
    statusDate : ''   
       
    })


				
  const [editorState, setEditorState] = useState(
    "",
  );
 /*
const createInit =     () => EditorState.createEmpty();

  
    const [editorState, setEditorState] = useState(
    createInit
  );
  */
  const  [convertedContent, setConvertedContent] = useState(null);
  
  
  const  [ quillHTML, setQuillHTML] = useState(null);
 
  const [ result , setResult ] = useState({});


 const callbackfunc = (output) => {
	 alert("callback function called restuned value is :" + JSON.stringify(output));
	 
	 
    setResult(output) ;
   
   console.log("after setResult : output received is :" + JSON.stringify(output));
  };



return (

        
        <div>    


    
    
      <header className="App-header">
        Rich Text Editor Example
      </header>
      alert("EDITBLOG : body for quill is :" + {editorState})
      
      
      
      
         {/*     <Editor  parentCallback = {handleCallback}> bodyHtml={editorState}  test={test}</Editor> */}

 <MyEditCkeditorComponent   id={location.state.paramId}  user={location.state.paramUser}  
 bodyHtml={editorState}> callbackfunc={callbackfunc}</MyEditCkeditorComponent>  

        
       {/*
        <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div> 

       */}
    </div>
  )
};

export default EditBlog;
