
import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import axios from 'axios';

import {  ACCESS_TOKEN } from '../../constants';



import { API_URL ,  API_IMAGESERVER_URL , UPLOAD_ENDPOINT  } from '../../constants';



//const API_URL = "http://localhost:8080";
//const API_IMAGESERVER_URL = "http://localhost:9090";
//* localhost changed to goeditor for final deployment


// const API_URL = "http://localhost:8080";
// const API_IMAGESERVER_URL = "http://localhost:9090";


// PUT IN CONSTANTS --------------------------------------------2023/07/03 ------------------
// const API_URL = "http://goproperties.in:8080";
// const API_IMAGESERVER_URL = "http://goproperties.in:9090";


// const UPLOAD_ENDPOINT = "upload_files";

// -----------------------------------------------------------------------------
const MyEditor = ({ handleChange, ...props }) => { 
    const [editorData , setEditorData] = useState("");

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
          //  body.append("upload", file);
           body.append("files", file);
     /*      axios.default.headers = {
           
           'Content-type': 'application/json',
           'Access-Control-Allow-Origin' : '*', 
           }
           */
           // let headers = new Headers();
          //  headers.append("content-type", "application/json");
            console.log("before fetch ");
            // *** alert("before fetch ");
            
             //'Content-type': 'application/json' ,  changed 20230528
            axios
				.post(`${API_URL}/api/${UPLOAD_ENDPOINT}`, body,
				
				 {
                        headers: {
                            "Content-Type": `multipart/form-data: boundary=add-random-characters`,
                           
                             'Access-Control-Allow-Origin' : '*',
                        },                    
                    }
                    )
				.then(response => {
				console.log("returned from axios");
				// *** alert("returned from axios" + JSON.stringify(response));
				
                 // *** alert("returned from axios" + response.data);
				resolve({
				 default: `${API_IMAGESERVER_URL}/blogDBimages/ckeditorImages/public/${response.data}`
                 

                
                
		
		  })
		  })		
				.catch(error => {
					reject("Server Error");
					console.log("Server Error : ", error);
				});
		});
            

          });
        }
      }
    };
  
  function uploadPlugin1(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  
 /*  textPartLanguage: [
                { title: 'Hindi', languageCode: 'hi' },
                { title: 'Gujarati', languageCode: 'gu' },
                { title: 'Arabic', languageCode: 'ar' },
                { title: 'French', languageCode: 'fr' },
                { title: 'Hebrew', languageCode: 'he' },
                { title: 'Spanish', languageCode: 'es' }
            ]
            
  */          
       return (
           <div className="App">
               <h2>Article Content</h2>
             
<span style={{fontSize: '20px'}}>

               <CKEditor
                   editor={ Editor }
                   config={{
                   mediaEmbed: {
                              previewsInData: true
                      },
                      
                              language: {
            textPartLanguage: [
                { title: 'Hindi', languageCode: 'hi' },
                { title: 'Gujarati', languageCode: 'gu' }
               
            ]
        },
        fontSize: {
            options: [
                9,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
                      
                          extraPlugins: [uploadPlugin1]
                     }}
                    
                  
                   data={editorData}
                   onReady={ editor => {
                       // You can store the "editor" and use when it is needed.
                       console.log( 'Editor is ready to use!', editor );
                       editor.execute( 'fontSize', { value: 15 } );
                   } }
                   onChange={ ( event, editor ) => {
                       const data = editor.getData();
                         console.log( "data is:" + data  );
                       console.log( { event, editor, data } );
                       var htmlData = editor.getData();
                       setEditorData(htmlData);
                      
                         handleChange(editor.getData());
                      // alert("html Data is :" +  htmlData );
                       
                   } }
                   onBlur={ ( event, editor ) => {
                       console.log( 'Blur.', editor );
                   } }
                   onFocus={ ( event, editor ) => {
                       console.log( 'Focus.', editor );
                   } }
                   {...props}
               />
         </span>

             
           </div>
       );   
}
export default MyEditor;
