  import React, { useState } from 'react';



import {  ACCESS_TOKEN } from '../constants';

import { BLOG_SITE_BASE_URL ,
         BLOG_MEDIA_BASE_URL,
         BLOG_REACT_BASE_URL } from '../constants';
         
import axios from "axios";


/* 
------------------------------------------------------------------------------------------

ref article : https://bobbyhadz.com/blog/react-export-multiple-functions

-------------------------------------------------------------------------------------
*/
//const c = new ConfigURL();


//const myHeaders = new Headers();

//myHeaders.append('Content-Type', 'application/json');

//if(localStorage.getItem(ACCESS_TOKEN)) {
//	console.log("access token is :"  + localStorage.getItem(ACCESS_TOKEN));
//	myHeaders.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN));
//}

  
  


export default {

     getBlogById: (id) => {
		 
  	  let config = {
			  headers : {
				  'Content-Type' : 'application/json' ,
	              'Authorization' : 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
	              'Access-Control-Allow-Origin' : '*',
			  }
	                  
	  }
	  

      const  baseURL = BLOG_SITE_BASE_URL + "/postapi";
      const  listPathURL  = "/getBlogById/" + id;  /* change here */
    	// ***   alert("before calling Axios myHeaders is " + config);
    	  return       axios.get(baseURL + listPathURL ,config)
    	      .then( function(response) {
    	    	  console.log("Response"+ response);
    	          console.log("total elements:" +response.data.totalElements );
    	          console.log("response content display:"  + response.data.content);
    	        
    	          console.log(response.data);
    	          console.log(response.status);
    	          console.log(response.statusText);
    	          console.log(response.headers);
    	          console.log(response.config);
    	         // ***   alert("THEN FUNCTION PART GETALL reponse  is :" + JSON.stringify(response))
    	          return(response)
    	        
    })
  
  

  }
  }