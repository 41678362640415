// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:6060/api';
export const ACCESS_TOKEN = 'accessToken';
export const RENT_LIST_SIZE = 2;
export const POLL_LIST_SIZE = 30;
export const MAX_CHOICES = 6;
export const POLL_QUESTION_MAX_LENGTH = 140;
export const POLL_CHOICE_MAX_LENGTH = 40;

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const MOBILE_MIN_LENGTH = 10;
export const MOBILE_MAX_LENGTH = 10;



export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;
export const BLOG_SITE_BASE_URL = 'https://goproperties.in:8443';
export const BLOG_MEDIA_BASE_URL = 'https://goproperties.in:9443';
export const BLOG_REACT_BASE_URL = 'https://goproperties.in:3000'; 
export const RENT_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
export const RENT_PDF_BASE_URL = 'http://localhost:8080/rentDBpdf/stream/invoice/';



export const API_URL = "https://goproperties.in:8443";
export const API_IMAGESERVER_URL = "https://goproperties.in:9443";


export const UPLOAD_ENDPOINT = "upload_files";